import { useMemo, useCallback, FC, MouseEventHandler } from 'react';

import employerButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/employer_button_click';
import { Text, Link as MagritteLink } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';

import ItAccreditation from 'lux/components/Employer/ItAccreditation';
import OrganizationForm from 'lux/components/Employer/OrganizationForm';
import TrustedEmployerCheck, { TrustedEmployerCheckSize } from 'lux/components/Employer/TrustedEmployerCheck';
import EmployerBadgesIconWrapper from 'lux/components/EmployerBadges/EmployerBadgesIconWrapper';
import AsyncHighlighter from 'lux/components/Markup/AsyncHighlighter';
import { Platform } from 'lux/models/locale.types';
import { BillingPlatform } from 'lux/models/price/product.types';
import { useSelector } from 'lux/modules/useSelector';

import usePreview from 'lux/components/VacancyView/hooks/usePreview';

interface VacancyCompanyNameMagritteProps {
    constructorBrandedVacancy?: boolean;
    // tempexp_30059_next-line
    shouldShowTrustedEmployerCheck?: boolean;
}

const VacancyCompanyNameMagritte: FC<VacancyCompanyNameMagritteProps> = ({
    constructorBrandedVacancy,
    // tempexp_30059_next-line
    shouldShowTrustedEmployerCheck = true,
}) => {
    const company = useSelector((state) => state.vacancyView.company);
    const request = useSelector((state) => state.request);
    const isCrossposted = useSelector(
        (state) =>
            state.locale.platform === Platform.Zarplata &&
            state.vacancyView.platforms.includes(BillingPlatform.HH) &&
            state.vacancyView.platforms.includes(BillingPlatform.ZP)
    );
    const isPreview = usePreview(request.url);
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const companyNameOnClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        (event) => {
            if (isPreview) {
                event.preventDefault();
                return;
            }
            if (vacancyId) {
                employerButtonClick({ vacancyId });
            }
        },
        [isPreview, vacancyId]
    );

    const { id, visibleName, employerOrganizationFormId, department } = company;
    const renderCompanyName = useMemo(() => {
        if (id) {
            if (isCrossposted) {
                return (
                    <Text Element="span" typography="subtitle-1-semibold">
                        <OrganizationForm organizationFormId={employerOrganizationFormId} />
                        <AsyncHighlighter>{visibleName}</AsyncHighlighter>
                    </Text>
                );
            }

            let companyHref = `/employer/${id}`;
            if (department) {
                companyHref += `?dpt=${department?.['@code']}`;
            }

            return (
                <span className="vacancy-company-name">
                    <MagritteLink
                        Element={Link}
                        data-qa="vacancy-company-name"
                        to={companyHref}
                        onClick={companyNameOnClick}
                        typography="subtitle-1-semibold"
                        inline
                    >
                        <Text Element="span" typography="subtitle-1-semibold">
                            <OrganizationForm organizationFormId={employerOrganizationFormId} />
                            <AsyncHighlighter>{visibleName}</AsyncHighlighter>
                        </Text>
                    </MagritteLink>
                </span>
            );
        }

        return (
            <AsyncHighlighter>
                <span className="vacancy-company-name">
                    <Text Element="span">{visibleName}</Text>
                </span>
            </AsyncHighlighter>
        );
    }, [id, visibleName, isCrossposted, department, companyNameOnClick, employerOrganizationFormId]);

    return (
        <div data-qa="vacancy-company__details" className="vacancy-company-details">
            {renderCompanyName}
            <EmployerBadgesIconWrapper>
                {shouldShowTrustedEmployerCheck && company['@trusted'] && (
                    <TrustedEmployerCheck size={TrustedEmployerCheckSize.Small} isMagritte />
                )}
                {company.accreditedITEmployer && <ItAccreditation isInverted={constructorBrandedVacancy} isMagritte />}
            </EmployerBadgesIconWrapper>
        </div>
    );
};

export default VacancyCompanyNameMagritte;
