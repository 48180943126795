import { FC, PropsWithChildren } from 'react';

import RowContent from 'lux/components/RowContent';

import useBranding from 'lux/components/VacancyView/hooks/useBranding';

interface VacancyRowContentProps {
    isChameleonEmployerView: boolean;
}

const VacancyRowContent: FC<VacancyRowContentProps & PropsWithChildren> = ({ children, isChameleonEmployerView }) => {
    const { isConstructor } = useBranding();

    if (isConstructor || isChameleonEmployerView) {
        return <>{children}</>;
    }

    return <RowContent>{children}</RowContent>;
};

export default VacancyRowContent;
