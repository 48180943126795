import { FC, ElementType, PropsWithChildren } from 'react';

import { Skeleton } from '@hh.ru/magritte-ui';
import VSpacing from 'bloko/blocks/vSpacing';

import ContainerForMicroFrontend from 'lux/components/CodeInjector/ContainerForMicroFrontend';
import { useIsRussia } from 'lux/hooks/useCountries';
import { useSelector } from 'lux/modules/useSelector';

interface EmployerReviewsSmallWidgetProps {
    Wrapper?: ElementType;
    isRedesign?: boolean;
    white?: boolean;
}

const DefaultWrapper: FC<PropsWithChildren> = ({ children }) => <>{children}</>;

const EmployerReviewsSmallWidget: FC<EmployerReviewsSmallWidgetProps> = ({
    Wrapper = DefaultWrapper,
    isRedesign,
    white = false,
}) => {
    const isRussia = useIsRussia();
    const isTrustedEmployer = useSelector(
        (state) => state.employerInfo.isTrusted || state.vacancyView.company['@trusted']
    );

    if (!isRussia || !isTrustedEmployer) {
        return null;
    }

    const place = white ? 'employerReviewsSmallWhite' : 'employerReviewsSmall';

    return (
        <Wrapper>
            {isRedesign && <VSpacing base={4} />}
            <ContainerForMicroFrontend
                place={place}
                preloadContent={!white && <Skeleton height={24} width={200} loading borderRadius={8} />}
            />
        </Wrapper>
    );
};

export default EmployerReviewsSmallWidget;
