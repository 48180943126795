import classnames from 'classnames';

import { Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Compensation from 'lux/components/Compensation';
import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

enum CompensationType {
    Net = 'net',
    Gross = 'gross',
}

const TrlKeys = {
    compensationNotSet: 'similarVacancyBlock.compensationNotSet',
    [CompensationType.Net]: 'employer.newVacancy.compensation.net',
    [CompensationType.Gross]: 'employer.newVacancy.compensation.gross',
};

interface VacancyCompensationProps {
    noCompensation?: boolean;
    isRedesign?: boolean;
    isNeutral?: boolean;
    inHeader?: boolean;
}

const VacancyCompensation: TranslatedComponent<VacancyCompensationProps> = ({
    isRedesign,
    trls,
    isNeutral,
    inHeader,
}) => {
    const { from, to, currencyCode, noCompensation, gross } = useSelector((state) => state.vacancyView.compensation);
    if (!from && !to) {
        return (
            <Text Element="span" typography={inHeader ? 'label-3-regular' : 'label-1-regular'}>
                {trls[TrlKeys.compensationNotSet]}
            </Text>
        );
    }

    if (!currencyCode) {
        return null;
    }
    let compensationType;
    if (gross === true) {
        compensationType = CompensationType.Gross;
    } else if (gross === false) {
        compensationType = CompensationType.Net;
    }

    return (
        <Text data-qa="vacancy-salary" Element={inHeader ? 'span' : 'div'}>
            <Text
                Element="span"
                typography={inHeader ? 'label-2-regular' : 'label-1-regular'}
                data-qa={`vacancy-salary-compensation-type-${compensationType || ''}`}
                style={isNeutral ? 'tertiary' : 'primary'}
            >
                <Compensation
                    from={from}
                    to={to}
                    currencyCode={currencyCode}
                    groupSeparator={'\u00A0'}
                    showNoSalaryMessage
                />
                {!noCompensation && compensationType && (
                    <span
                        className={classnames({
                            'vacancy-salary-compensation-type': isRedesign,
                        })}
                    >
                        {' '}
                        {trls[TrlKeys[compensationType]]}
                    </span>
                )}
            </Text>
        </Text>
    );
};

export default translation(VacancyCompensation);
