import { FC, Fragment } from 'react';

import MetroPoint from 'lux/components/MetroPoint';
import { VacancyViewMetroStation } from 'lux/models/vacancyView.types';

interface MetroListProps {
    metroList?: Pick<VacancyViewMetroStation, 'name' | 'line'>[];
    small?: boolean;
}

const MetroList: FC<MetroListProps> = ({ metroList, small }) => {
    return (
        <>
            {metroList?.map((metro, index) => (
                <Fragment key={index}>
                    {index !== 0 && ', '}
                    <MetroPoint name={metro.name} color={metro?.line?.color} small={small} />
                </Fragment>
            ))}
        </>
    );
};

export default MetroList;
