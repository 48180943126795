import classnames from 'classnames';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { ExperienceOption } from 'lux/models/search/searchCriteria.types';
import { useSelector } from 'lux/modules/useSelector';

const TrlKeys = {
    workExperience: 'vacancyInfo.workExperience',
    experience: {
        [ExperienceOption.NoExperience]: 'workExperience.noExperience',
        [ExperienceOption.Between1And3]: 'workExperience.between1And3',
        [ExperienceOption.Between3And6]: 'workExperience.between3And6',
        [ExperienceOption.MoreThan6]: 'workExperience.moreThan6',
    },
};

interface VacancyWorkExperienceProps {
    isRedesign?: boolean;
}

const VacancyWorkExperience: TranslatedComponent<VacancyWorkExperienceProps> = ({ isRedesign, trls }) => {
    const workExperience = useSelector((state) => state.vacancyView.workExperience);
    if (!workExperience) {
        return null;
    }
    return (
        <p
            className={classnames({
                'vacancy-description-list-item': isRedesign,
            })}
        >
            {trls[TrlKeys.workExperience]}
            {': '}
            <span data-qa="vacancy-experience">{trls[TrlKeys.experience[workExperience]]}</span>
        </p>
    );
};

export default translation(VacancyWorkExperience);
