import classnames from 'classnames';

import Gap from 'bloko/blocks/gap';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import useResumeIdResponseStatuses from 'lux/hooks/useResumeIdResponseStatuses';
import { useSelector } from 'lux/modules/useSelector';

import RespondButton from 'lux/components/VacancyView/ResponseStatus/RespondButton';

const TrlKeys = {
    responded: 'vacancy.view.topic.status.responded',
    discarded: 'vacancy.view.topic.status.discard',
    invited: 'vacancy.view.topic.status.invitation',
};

interface ResponseStatusProps {
    headerMode?: boolean;
}

const ResponseStatus: TranslatedComponent<ResponseStatusProps> = ({ trls, headerMode }) => {
    const { vacancyId } = useSelector((state) => state.vacancyView);
    const responseStatuses = useSelector((state) => state.applicantVacancyResponseStatuses);
    const resumeIdResponseStatuses = useResumeIdResponseStatuses(vacancyId);
    if (!vacancyId) {
        return null;
    }
    const responseStatus = responseStatuses[vacancyId];
    if (!responseStatus?.usedResumeIds?.length) {
        return null;
    }

    const renderTopicStatus = () => {
        if (resumeIdResponseStatuses === null) {
            return null;
        }
        if (resumeIdResponseStatuses.invited.length) {
            return trls[TrlKeys.invited];
        }
        if (resumeIdResponseStatuses.discarded.length) {
            return trls[TrlKeys.discarded];
        }
        return trls[TrlKeys.responded];
    };

    return (
        <Gap bottom>
            <div
                className={classnames('vacancy-response', {
                    'vacancy-response_invited': !!resumeIdResponseStatuses?.invited?.length,
                    'vacancy-response_header-mode': headerMode,
                })}
            >
                <div className="vacancy-response__already-replied">{renderTopicStatus()}</div>
                <RespondButton
                    vacancyId={vacancyId}
                    unusedResumeIds={responseStatus.unusedResumeIds}
                    hiddenResumeIds={responseStatus.hiddenResumeIds}
                />
            </div>
        </Gap>
    );
};

export default translation(ResponseStatus);
