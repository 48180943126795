// tempexp_30059_file
import { FC, useEffect, useRef } from 'react';

import approvedIndicationElementShown, {
    ViewLabel,
} from '@hh.ru/analytics-js-events/build/xhh/employer/experiments/PORTFOLIO-30059/approved_indication_element_shown';
import unverifiedIndicationSendDocsButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/experiments/PORTFOLIO-30059/unverified_indication_send_docs_button_click';
import unverifiedIndicationSendDocsElementShown from '@hh.ru/analytics-js-events/build/xhh/employer/experiments/PORTFOLIO-30059/unverified_indication_send_docs_element_shown';
import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink from 'bloko/blocks/link';
import VSpacing from 'bloko/blocks/vSpacing';

import Label, { LabelType, LabelColor } from 'lux/components/Label';
import usePreview from 'lux/components/VacancyView/hooks/usePreview';
import { EmployerState } from 'lux/models/employerStatus';
import { useSelector } from 'lux/modules/useSelector';

import styles from './styles.less';

const VacancyCompanyVerificationStatus: FC = () => {
    const employerState = useSelector(({ employerStatus }) => employerStatus.state);
    const vacancyEmployerState = useSelector(({ vacancyView }) => vacancyView.company['@state']);
    const companyState = employerState || vacancyEmployerState;
    const hasLoadedDocuments = useSelector(({ employerDocuments }) => !!employerDocuments.length);
    const request = useSelector((state) => state.request);
    const isPreview = usePreview(request.url);
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const approvedIndicationElement = useRef(null);
    const unverifiedIndicationSendDocsElement = useRef(null);
    const draftId = useSelector((state) => state.router.location.query?.draftId);
    const analyticsParams = {
        draftId,
        vacancyId: vacancyId ? String(vacancyId) : null,
        viewLabel: (isPreview ? 'vacancy_preview' : 'vacancy_view') as ViewLabel,
    };

    useEffect(() => {
        if (companyState === EmployerState.Approved && approvedIndicationElement.current) {
            approvedIndicationElementShown(approvedIndicationElement.current, analyticsParams);
        }

        if (
            (companyState === EmployerState.New || companyState === EmployerState.NotVerified) &&
            !hasLoadedDocuments &&
            unverifiedIndicationSendDocsElement.current
        ) {
            unverifiedIndicationSendDocsElementShown(unverifiedIndicationSendDocsElement.current, analyticsParams);
        }
    }, [analyticsParams, companyState, hasLoadedDocuments]);

    if (companyState === EmployerState.New || companyState === EmployerState.NotVerified) {
        return (
            <>
                <VSpacing base={2} />
                <div className={styles.notVerifiegContainer}>
                    <Label
                        type={LabelType.CustomContent}
                        color={LabelColor.LightRed}
                        Content="Непроверенная регистрация"
                    />
                    {!hasLoadedDocuments && (
                        <BlokoLink
                            disableVisited
                            Element={Link}
                            target="_blank"
                            to="/employer/documents"
                            onClick={() => {
                                unverifiedIndicationSendDocsButtonClick(analyticsParams);
                            }}
                        >
                            Предоставить документы
                        </BlokoLink>
                    )}
                </div>
            </>
        );
    }

    if (companyState === EmployerState.Approved) {
        return (
            <div ref={approvedIndicationElement}>
                <VSpacing base={2} />
                <Label
                    type={LabelType.CustomContent}
                    color={LabelColor.LightGreen}
                    Content="Подтвержденная регистрация"
                />
            </div>
        );
    }

    return null;
};

export default VacancyCompanyVerificationStatus;
