import { FC, useEffect, useRef, RefObject, ReactNode } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Backplate, MainContentWrapper } from '@hh.ru/branding-packages';
import Column, { ColumnsRow } from 'bloko/blocks/column';
import VSpacing from 'bloko/blocks/vSpacing';

import { useSelector } from 'lux/modules/useSelector';

import Form from 'lux/components/VacancyView/SignUpBlock/Form';

import styles from './sign-up-block.less';

interface Props {
    isBrandingConstructor?: boolean;
    blockRef: RefObject<HTMLDivElement>;
}

const SignUpBlock: FC<Props> = ({ isBrandingConstructor, blockRef }) => {
    const showSignupForm = useSelector((state) => state.vacancyView.showSignupForm);
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const analyticsSent = useRef(false);

    useEffect(() => {
        if (!showSignupForm) {
            return undefined;
        }
        const signupFormTop = blockRef?.current?.offsetTop || 0;
        const scrollHandler = () => {
            if (!analyticsSent.current && window.scrollY + window.innerHeight / 2 > signupFormTop) {
                Analytics.sendHHEvent('applicantRegistrationAttempt', {
                    vacancy_id: vacancyId, // eslint-disable-line camelcase
                });
                analyticsSent.current = true;
            }
        };
        window.addEventListener('scroll', scrollHandler);
        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, [blockRef, showSignupForm, vacancyId]);

    if (!showSignupForm) {
        return null;
    }

    const renderLayout = (content: ReactNode) => {
        if (isBrandingConstructor) {
            return (
                <MainContentWrapper>
                    <Backplate separate forceStylesBackplate>
                        {content}
                    </Backplate>
                </MainContentWrapper>
            );
        }
        return (
            <ColumnsRow>
                <Column xs="4" s="8" m="12" l="10">
                    {content}
                    <VSpacing base={9} xs={6} />
                </Column>
            </ColumnsRow>
        );
    };

    return (
        <div className="noprint">
            {renderLayout(
                <div className={styles.wrapper} ref={blockRef}>
                    <Form />
                </div>
            )}
        </div>
    );
};

export default SignUpBlock;
