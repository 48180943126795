import { ReactElement } from 'react';
import { useDispatch } from 'react-redux';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import vacancyAskQuestionButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/vacancy_ask_question_button_click';
import vacancyAskQuestionElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/vacancy_ask_question_element_shown';
import Button, { ButtonKind, ButtonAppearance } from 'bloko/blocks/button';
import InputText from 'bloko/blocks/inputText';
import Loading, { LoadingColor, LoadingScale } from 'bloko/blocks/loading';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { fetchVacancyResponsePopup } from 'lux/components/VacancyResponseLink';
import useQuickResponse from 'lux/components/VacancyResponseLink/useQuickResponse';
import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

import styles from './VacancyResponseQuestions.less';

const TrlKeys = {
    send: 'vacancy.view.responseQuestions.send',
};

interface QuestionsInputProps {
    value: string;
    vacancyId: number;
    suggest: ReactElement | null;
    onChange: (value: string) => void;
}

const QuestionsInput: TranslatedComponent<QuestionsInputProps> = ({ trls, value, onChange, suggest, vacancyId }) => {
    const dispatch = useDispatch();
    const isXS = useBreakpoint() === Breakpoint.XS;
    const handleQuickResponse = useQuickResponse();

    const responseStatus = useSelector((state) => state.applicantVacancyResponseStatuses[vacancyId]);

    const sendQuestion = () => {
        vacancyAskQuestionButtonClick({
            vacancyId,
            questionText: value,
            questionFrom: suggest ? 'after_description' : 'before_description',
        });

        dispatch(
            fetchVacancyResponsePopup({
                vacancyId,
                isTest: 'no',
                withoutTest: 'no',
                lux: true,
                handleQuickResponse,
                enableRelocationWarning: true,
                isQuestion: true,
                hhtmFromLabel: '',
                payload: {
                    letter: value,
                    // eslint-disable-next-line camelcase
                    response_source: 'APPLICANT_QUESTIONS',
                },
            })
        );
    };

    return (
        <ElementShownAnchor
            vacancyId={vacancyId}
            questionFrom={suggest ? 'after_description' : 'before_description'}
            fn={vacancyAskQuestionElementShown}
        >
            <div className={styles.input}>
                <div className={styles.inputWrapper}>
                    <InputText value={value} onChange={onChange} />
                    {isXS && suggest}
                    <Button
                        onClick={sendQuestion}
                        kind={ButtonKind.Primary}
                        appearance={ButtonAppearance.Filled}
                        stretched={isXS}
                        disabled={!value || responseStatus?.isFetching}
                        loading={
                            responseStatus?.isFetchingWithQuestionResponse && (
                                <Loading initial={LoadingColor.White} scale={LoadingScale.Small} />
                            )
                        }
                    >
                        {trls[TrlKeys.send]}
                    </Button>
                </div>
                {!isXS && suggest}
            </div>
        </ElementShownAnchor>
    );
};

export default translation(QuestionsInput);
