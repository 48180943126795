import { FC } from 'react';

import VSpacing from 'bloko/blocks/vSpacing';

import EmployerReviewsWidgetSmall from 'lux/components/EmployerReviews/WidgetSmall';
import { useSelector } from 'lux/modules/useSelector';

import EmployerCards from 'lux/components/VacancyView/EmployerCards';
import VacancyCompanyAddress from 'lux/components/VacancyView/VacancyCompanyAddress';
import VacancyCompanyLogo from 'lux/components/VacancyView/VacancyCompanyLogo';
import VacancyCompanyName from 'lux/components/VacancyView/VacancyCompanyName';

const VacancyCompanyRedesigned: FC = () => {
    const companyId = useSelector((state) => state.vacancyView.company.id);
    const badges = useSelector((state) => state.vacancyView.company.badges);
    const trusted = useSelector((state) => state.vacancyView.company['@trusted']);
    return (
        <div className="vacancy-company-redesigned" data-qa="vacancy-company">
            <VacancyCompanyLogo isRedesign />
            <VacancyCompanyName isRedesign />
            <div className="noprint">{trusted && <EmployerReviewsWidgetSmall isRedesign />}</div>
            <VSpacing base={3} />
            <EmployerCards companyId={companyId} badges={badges} />
            <VacancyCompanyAddress isRedesign />
        </div>
    );
};

export default VacancyCompanyRedesigned;
