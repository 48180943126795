import { DefaultRootState } from 'react-redux';

import { CountryId } from 'lux/models/countryId';
import { UserType } from 'lux/models/userType';

const checkWidgetRender = (state: DefaultRootState): boolean => {
    const isTrusted = state.employerInfo.isTrusted || state.vacancyView.company['@trusted'];
    const isRussia = state.countryId === CountryId.Russia;
    const hasBigWidgetMicrofrontend = !!state.microFrontends.employerReviewsBig;

    return isRussia && isTrusted && hasBigWidgetMicrofrontend;
};

export const checkWidgetRenderForVacancyView = (state: DefaultRootState): boolean => {
    const userType = state.userType;
    return [UserType.Anonymous, UserType.Applicant, UserType.NedoUser].includes(userType) && checkWidgetRender(state);
};

export default checkWidgetRender;
