import { useCallback } from 'react';
import { DefaultRootState } from 'react-redux';

import { Title, VSpacing } from '@hh.ru/magritte-ui';
import Conversion from 'bloko/blocks/conversion';
import InfoInline from 'bloko/blocks/drop/InfoInline';
import HoverTip, { TipPlacement } from 'bloko/blocks/drop/Tip/HoverTip';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { UserType } from 'lux/models/userType';
import { useSelector } from 'lux/modules/useSelector';

import VacancySectionWrapper from 'lux/components/VacancyView/VacancySectionWrapper';
import VacancySkillList from 'lux/components/VacancyView/VacancySkillList';

const TrlKeys = {
    keySkills: 'rb.title.keySkills',
    confirm: 'vacancy.keySkills.confirm',
    otherwise: 'vacancy.keySkills.confirm.otherwise',
    providers: {
        one: 'vacancy.keySkills.providers.one',
        many: 'vacancy.keySkills.providers.many',
    },
    otherProviders: {
        one: 'vacancy.keySkills.providers.one.other',
        many: 'vacancy.keySkills.providers.many.other',
    },
};

export const checkVacancyKeySkillsRender = ({ vacancyView }: DefaultRootState): boolean =>
    !!vacancyView.keySkills?.keySkill?.length;

interface VacancyKeySkillsProps {
    hasVacancySectionWrapper?: boolean;
}

const VacancyKeySkills: TranslatedComponent<VacancyKeySkillsProps> = ({ hasVacancySectionWrapper = true, trls }) => {
    const {
        keySkills,
        confirmableKeySkills: { providers },
    } = useSelector((state) => state.vacancyView);
    const showKeySkills = useSelector(checkVacancyKeySkillsRender);
    const isEmployer = useSelector((state) => state.userType === UserType.Employer);
    const hasProviders = providers.length > 0;

    const renderProvidersInfo = useCallback(() => {
        if (!hasProviders) {
            return null;
        }
        const promoted = providers.filter(({ promoted }) => promoted);
        const other = providers.filter(({ promoted }) => !promoted);
        const hasBothProviders = promoted.length > 0 && other.length > 0;
        const conversionKey = hasBothProviders ? 'otherProviders' : 'providers';

        return (
            <>
                {trls[TrlKeys.confirm]}
                {promoted.map(({ name }) => name).join(', ')}
                {hasBothProviders && ` ${trls[TrlKeys.otherwise]}`}
                {other.length > 0 && (
                    <HoverTip
                        placement={TipPlacement.Top}
                        render={() => other.map(({ name }) => <div key={name}>{name}</div>)}
                    >
                        <Link appearance={LinkAppearance.Pseudo}>
                            <Conversion
                                value={other.length}
                                one={trls[TrlKeys[conversionKey].one]}
                                some={trls[TrlKeys[conversionKey].many]}
                                many={trls[TrlKeys[conversionKey].many]}
                            />
                        </Link>
                    </HoverTip>
                )}
            </>
        );
    }, [hasProviders, providers, trls]);

    if (keySkills === null || !showKeySkills) {
        return null;
    }

    return (
        <VacancySectionWrapper hasWrapper={hasVacancySectionWrapper}>
            <Title Element="h2" size="extra-small">
                {trls[TrlKeys.keySkills]}
            </Title>
            <VSpacing default={16} />
            <InfoInline withPointer show={hasProviders} showCloseButton={false} render={renderProvidersInfo}>
                <VacancySkillList list={keySkills.keySkill} shouldBeShorted={isEmployer} />
            </InfoInline>
        </VacancySectionWrapper>
    );
};

export default translation(VacancyKeySkills);
