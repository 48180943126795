import { DefaultRootState } from 'react-redux';

import { VSpacingContainer } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

import VacancyAdditionalAccept from 'lux/components/VacancyView/VacancyAdditionalAccept';
import VacancyHasTestText from 'lux/components/VacancyView/VacancyHasTestText';
import VacancySectionWrapper from 'lux/components/VacancyView/VacancySectionWrapper';

const TrlKeys = {
    acceptHandicapped: 'vacancy.view.acceptHandicapped',
    acceptHandicappedHint: 'acceptHandicapped.info',
    acceptKids: 'vacancy.view.acceptKids',
    acceptKidsHint: 'acceptKids.info',
};

export const checkVacancyAdditionalAcceptsRender = ({ vacancyView }: DefaultRootState): boolean =>
    vacancyView['@acceptHandicapped'] || vacancyView['@acceptKids'];
interface VacancyAdditionalAcceptsProps {
    hasVacancySectionWrapper?: boolean;
    isRedesign: boolean;
}
const VacancyAdditionalAccepts: TranslatedComponent<VacancyAdditionalAcceptsProps> = ({
    isRedesign,
    hasVacancySectionWrapper = true,
    trls,
}) => {
    const acceptHandicapped = useSelector(({ vacancyView }) => vacancyView['@acceptHandicapped']);
    const acceptKids = useSelector(({ vacancyView }) => vacancyView['@acceptKids']);
    const showAdditionalAccepts = useSelector(checkVacancyAdditionalAcceptsRender) || !isRedesign;
    if (!showAdditionalAccepts) {
        return null;
    }
    return (
        <VacancySectionWrapper hasWrapper={hasVacancySectionWrapper}>
            <VSpacingContainer default={10}>
                <VacancyHasTestText />
                {acceptHandicapped && (
                    <VacancyAdditionalAccept
                        icon="handicapped"
                        text={trls[TrlKeys.acceptHandicapped]}
                        hint={trls[TrlKeys.acceptHandicappedHint]}
                    />
                )}
                {acceptKids && (
                    <VacancyAdditionalAccept
                        icon="kids"
                        text={trls[TrlKeys.acceptKids]}
                        hint={trls[TrlKeys.acceptKidsHint]}
                    />
                )}
            </VSpacingContainer>
        </VacancySectionWrapper>
    );
};

export default translation(VacancyAdditionalAccepts);
