/* eslint-disable import/prefer-default-export */
import { RefObject, useEffect } from 'react';

import clickmeBannerButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/clickme_banner_button_click';

import { useSelector } from 'lux/modules/useSelector';

const PLACE_TO_POSITION_MAPPING = {
    '245': 1,
    '651': 2,
    '652': 3,
    '653': 4,
};

interface UseTrackBannersClickEventProps {
    containerRef: RefObject<HTMLDivElement>;
}

export const useTrackBannersClickEvent = ({ containerRef }: UseTrackBannersClickEventProps): void => {
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId as number);
    useEffect(() => {
        const container = containerRef.current;
        const clickHandler = (event: MouseEvent) => {
            const matchedElement: HTMLElement | null = (event.target as HTMLElement).closest('[data-banner-id]');
            if (matchedElement) {
                const bannerId = matchedElement.dataset.bannerId;
                if (bannerId && bannerId in PLACE_TO_POSITION_MAPPING) {
                    clickmeBannerButtonClick({
                        vacancyId,
                        position: PLACE_TO_POSITION_MAPPING[bannerId as keyof typeof PLACE_TO_POSITION_MAPPING],
                    });
                }
            }
        };
        if (container) {
            container.addEventListener('click', clickHandler);
        }
        return () => {
            if (container) {
                container.removeEventListener('click', clickHandler);
            }
        };
    }, [containerRef, vacancyId]);
};
