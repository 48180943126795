import { useMemo } from 'react';

import { CHAMELEON_COLUMNS } from 'lux/components/VacancyView/brandingConstants';
import useBranding from 'lux/components/VacancyView/hooks/useBranding';

const DEFAULT_COLUMN_PARAMS = {
    xs: '4',
    s: '8',
    m: '8',
    l: '10',
};

export default () => {
    const { isChameleon } = useBranding();

    return useMemo(
        () => ({
            contentColumnParams: {
                ...DEFAULT_COLUMN_PARAMS,
                ...(isChameleon && CHAMELEON_COLUMNS),
            },
            bottomColumnParams: {
                ...DEFAULT_COLUMN_PARAMS,
                ...(isChameleon && CHAMELEON_COLUMNS),
            },
        }),
        [isChameleon]
    );
};
