import { FC, PropsWithChildren, useEffect } from 'react';
import classnames from 'classnames';

import Analytics from '@hh.ru/analytics-js';
import { Text } from '@hh.ru/magritte-ui';
import Column, { ColumnsRow } from 'bloko/blocks/column';
import VSpacing from 'bloko/blocks/vSpacing';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import EmployerReviewsWidgetSmall from 'lux/components/EmployerReviews/WidgetSmall';
// tempexp_30059_next-line
import useExperiment from 'lux/hooks/useExperiment';
import { useIsZarplataPlatform } from 'lux/hooks/usePlatform';
import { useIsHhru } from 'lux/hooks/useSites';
import { useSelector } from 'lux/modules/useSelector';

import EmployerCards from 'lux/components/VacancyView/EmployerCards';
import VacancyCompanyAddress from 'lux/components/VacancyView/VacancyCompanyAddress';
import VacancyCompanyLogo from 'lux/components/VacancyView/VacancyCompanyLogo';
import VacancyCompanyNameMagritte from 'lux/components/VacancyView/VacancyCompanyNameMagritte';
// tempexp_30059_next-line
import VacancyCompanyVerificationStatus from 'lux/components/VacancyView/VacancyCompanyVerificationStatus';
import useCompanyLogo from 'lux/components/VacancyView/hooks/useCompanyLogo';

const ReviewWidgetWrapper: FC<PropsWithChildren> = ({ children }) => (
    <>
        <VSpacing base={4} />
        {children}
    </>
);

interface VacancyCompanyProps {
    constructorBrandedVacancy?: boolean;
    hasWrapper?: boolean;
}

const VacancyCompany: FC<VacancyCompanyProps> = ({ constructorBrandedVacancy, hasWrapper = true }) => {
    const { id: companyId, badges } = useSelector(({ vacancyView }) => vacancyView.company);
    const trusted = useSelector(({ vacancyView }) => vacancyView.company['@trusted']);
    const companyLogo = useCompanyLogo();

    // tempexp_30059_start
    const vacancyEmployerState = useSelector(({ vacancyView }) => vacancyView.company['@state']);
    const employerId = useSelector(({ employerId }) => employerId);
    const employerState = useSelector(({ employerStatus }) => employerStatus.state);
    const isPrint = useSelector(({ router }) => !!router.location.query.print);
    const draftId = useSelector((state) => state.router.location.query?.draftId);
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const isHHRu = useIsHhru();

    const isShowCompanyRegStatusExp = useExperiment(
        'new_employer_documents',
        !isPrint &&
            ['APPROVED', 'NEW', 'NOT_VERIFIED'].includes(employerState || vacancyEmployerState) &&
            Number(companyId) === Number(employerId) &&
            isHHRu
    );

    useEffect(() => {
        if (employerId && Number(companyId) === Number(employerId)) {
            Analytics.sendHHEvent('vacancy_view_own', {
                vacancyId,
                draftId,
            });
        }
    }, [companyId, draftId, employerId, vacancyId]);
    // tempexp_30059_end

    const breakpoint = useBreakpoint(Breakpoint.L);
    const isZarplata = useIsZarplataPlatform();
    const showCompanyLogo = !constructorBrandedVacancy || (constructorBrandedVacancy && breakpoint === Breakpoint.XS);
    const showReviewsWidget =
        trusted && (!constructorBrandedVacancy || (constructorBrandedVacancy && breakpoint !== Breakpoint.XS));

    const vacancyCompanyContent = (
        <div className="vacancy-company-wrapper">
            <div
                className={classnames('vacancy-company', {
                    'vacancy-company_branded-with-logo': constructorBrandedVacancy && companyLogo,
                    'vacancy-company_with-logo': !constructorBrandedVacancy && companyLogo,
                })}
                data-qa="vacancy-company"
            >
                <div className="vacancy-company-top">
                    <div className="vacancy-company-top-details">
                        <VacancyCompanyNameMagritte
                            constructorBrandedVacancy={constructorBrandedVacancy}
                            // tempexp_30059_next-line
                            shouldShowTrustedEmployerCheck={!isShowCompanyRegStatusExp}
                        />
                        {/* tempexp_30059_next-line */}
                        {isShowCompanyRegStatusExp && <VacancyCompanyVerificationStatus />}
                        {showReviewsWidget && (
                            <>
                                <EmployerReviewsWidgetSmall Wrapper={ReviewWidgetWrapper} />
                                <VSpacing base={4} />
                            </>
                        )}
                        <VacancyCompanyAddress
                            WrapperComponent={
                                isZarplata
                                    ? ({ children }) => (
                                          <Text Element="span" data-qa="vacancy-view-location">
                                              {children}
                                          </Text>
                                      )
                                    : undefined
                            }
                        />
                    </div>
                    {showCompanyLogo && <VacancyCompanyLogo constructorBrandedVacancy={constructorBrandedVacancy} />}
                </div>
                <VSpacing base={4} />
                <EmployerCards companyId={companyId} badges={badges} isEmployerView />
                <VSpacing base={6} />
            </div>
        </div>
    );

    if (!hasWrapper) {
        return <>{vacancyCompanyContent}</>;
    }

    return (
        <>
            <ColumnsRow>
                <Column xs="4" s={constructorBrandedVacancy ? '6' : '8'} m="8" l="10">
                    {vacancyCompanyContent}
                </Column>
            </ColumnsRow>
        </>
    );
};

export default VacancyCompany;
