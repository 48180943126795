import classnames from 'classnames';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

const TrlKeys = {
    acceptTemporary: 'vacancy.view.acceptTemporary',
};

interface VacancyAcceptTemporaryProps {
    isRedesign?: boolean;
}

const VacancyAcceptTemporary: TranslatedComponent<VacancyAcceptTemporaryProps> = ({ isRedesign, trls }) => {
    const acceptTemporary = useSelector((store) => store.vacancyView.acceptTemporary);
    if (!acceptTemporary) {
        return null;
    }
    return (
        <p
            className={classnames({ 'vacancy-description-list-item': isRedesign })}
            data-qa="vacancy-view-accept-temporary"
        >
            {trls[TrlKeys.acceptTemporary]}
        </p>
    );
};

export default translation(VacancyAcceptTemporary);
