import { FC, ReactNode, useEffect, useRef } from 'react';
import classnames from 'classnames';

import employerCardButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/employer_card_button_click';
import employerCardElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/employer_card_element_shown';
import Link from '@hh.ru/redux-spa-middleware/lib/Link';
import { HoverTip } from 'bloko/blocks/drop';
import { TipLayer, TipPlacement } from 'bloko/blocks/drop/Tip';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';

import { useSelector } from 'lux/modules/useSelector';

import style from './employer-cards.less';

export enum CardVariant {
    Rating = 'rating',
    HrBrand = 'hr-brand',
    EmployerReviews = 'employer-reviews',
    InsiderInterview = 'insider-interview',
    ItAccreditation = 'employer-it-accreditation',
}

interface CardProps {
    variant: CardVariant;
    title: string;
    subtitle: string;
    position: number;
    dataQa: string;
    url?: string;
    tip?: ReactNode;
    isEmployerView?: boolean;
}

const Card: FC<CardProps> = ({ variant, title, subtitle, position, url, tip, dataQa, isEmployerView = false }) => {
    const wrapperRef = useRef(null);
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId as number);

    useEffect(() => {
        if (wrapperRef.current) {
            employerCardElementShown(wrapperRef.current, { vacancyId, type: variant, position });
        }
    }, [position, variant, vacancyId]);

    const handleClick = () => {
        employerCardButtonClick({ vacancyId, type: variant, position });
    };

    const renderWrapper = (content: ReactNode) => {
        const props = {
            'data-qa': dataQa,
            className: classnames(style.employerCard, {
                [style.employerCardEmployerView]: isEmployerView,
                [style.employerCardRating]: variant === CardVariant.Rating,
                [style.employerCardHrBrand]: variant === CardVariant.HrBrand,
                [style.employerCardEmployerReviews]: variant === CardVariant.EmployerReviews,
                [style.employerCardInsiderInterview]: variant === CardVariant.InsiderInterview,
                [style.employerCardItAccreditation]: variant === CardVariant.ItAccreditation,
            }),
            ref: wrapperRef,
            onClick: handleClick,
        };
        const wrapper = url ? (
            <Link {...props} target="_blank" rel="nofollow noindex" to={url}>
                {content}
            </Link>
        ) : (
            <div {...props}>{content}</div>
        );

        if (tip) {
            return (
                <HoverTip
                    dataQa="employer-card-tip"
                    placement={TipPlacement.Top}
                    layer={TipLayer.Overlay}
                    host={!process.env.LUX_SERVER ? document.body : null}
                    render={() => tip}
                >
                    {wrapper}
                </HoverTip>
            );
        }

        return wrapper;
    };

    return renderWrapper(
        <>
            <VSpacing base={3} />
            <div
                className={classnames(style.employerCardTitle, {
                    [style.employerCardTitleEmployerView]: isEmployerView,
                })}
            >
                <Text strong size={isEmployerView ? TextSize.Small : TextSize.Medium}>
                    {title}
                </Text>
                <div
                    className={classnames(style.employerCardImageWrapper, {
                        [style.employerCardImageWrapperEmployerView]: isEmployerView,
                    })}
                >
                    <span
                        className={classnames(style.employerCardImage, {
                            [style.employerCardImageEmployerView]: isEmployerView,
                            [style.employerCardImageRating]: variant === CardVariant.Rating,
                            [style.employerCardImageHrBrand]: variant === CardVariant.HrBrand,
                            [style.employerCardImageEmployerReviews]: variant === CardVariant.EmployerReviews,
                            [style.employerCardImageInsiderInterview]: variant === CardVariant.InsiderInterview,
                            [style.employerCardImageItAccreditation]: variant === CardVariant.ItAccreditation,
                        })}
                    />
                </div>
            </div>
            <VSpacing base={1} />
            <Text size={TextSize.Small}>{subtitle}</Text>
            <VSpacing base={3} />
        </>
    );
};

export default Card;
