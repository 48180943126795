import { FC, useCallback, ComponentType } from 'react';

import { sendResponseAttempt } from 'Modules/VacancyAnalytics';
import ApplicantSignInSignUpFlow from 'lux/components/ApplicantSignInSignUpFlow';
import { useSelector } from 'lux/modules/useSelector';

import MultiAccountGroupingStep from 'lux/components/VacancyView/SignUpBlock/Form/MultiAccountGroupingStep';
import SignInStep, { SignInStepProps } from 'lux/components/VacancyView/SignUpBlock/Form/SignInStep';
import SignUpStep from 'lux/components/VacancyView/SignUpBlock/Form/SignUpStep';
import VerificationStep from 'lux/components/VacancyView/SignUpBlock/Form/VerificationStep';

const SignupForm: FC = () => {
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const employerId = useSelector((state) => state.vacancyView.company?.id);

    const SignIn = useCallback<ComponentType<Omit<SignInStepProps, 'setHeader'>>>(
        (props) => (
            <SignInStep
                {...props}
                callbackSubmit={() => {
                    if (!vacancyId) {
                        return;
                    }
                    sendResponseAttempt({ vacancyId, employerId, hhtmSourceLabel: 'vacancy_signup_form' });
                }}
            />
        ),
        [employerId, vacancyId]
    );

    return (
        <ApplicantSignInSignUpFlow
            SignIn={SignIn}
            Verification={VerificationStep}
            MultiAccountGrouping={MultiAccountGroupingStep}
            SignUp={SignUpStep}
        />
    );
};

export default SignupForm;
