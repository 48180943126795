import { DefaultRootState } from 'react-redux';

import { Title } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import SkillsSurvey from 'lux/components/SkillsSurvey';
import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

import VacancySectionWrapper from 'lux/components/VacancyView/VacancySectionWrapper';

export const checkVacancySkillSurveyRender = (state: DefaultRootState): boolean =>
    !!state.vacancyView.showSkillsSurvey && !!state.vacancyView.skillsSurveyProfession;

const Features = {
    showSkillSurveyOnVacancyEdit: 'show_skill_survey_on_vacancy_edit',
};

const TrlKeys = {
    titleBase: 'vacancy.title.skillsSurvey.base',
    edit: 'vacancy.skillSurvey.edit',
};

interface VacancySkillSurveyProps {
    hasVacancySectionWrapper?: boolean;
}

const VacancySkillSurvey: TranslatedComponent<VacancySkillSurveyProps> = ({
    hasVacancySectionWrapper = true,
    trls,
}) => {
    const skillSurveyTrls = useSelector(({ skillsSurveyTrls }) => skillsSurveyTrls);
    const availableVacancyActions = useSelector(({ availableVacancyActions }) => availableVacancyActions);
    const vacancyId = useSelector(({ vacancyView }) => vacancyView.vacancyId);
    const showEdit = useSelector(({ features }) => !features[Features.showSkillSurveyOnVacancyEdit]);
    const showSkillsSurvey = useSelector(checkVacancySkillSurveyRender);
    const skillsSurveyProfession = useSelector((state) => state.vacancyView.skillsSurveyProfession);
    if (!showSkillsSurvey || typeof skillsSurveyProfession !== 'string') {
        return null;
    }

    return (
        <VacancySectionWrapper hasWrapper={hasVacancySectionWrapper}>
            <Title Element="h2" size="extra-small">
                {format(trls[TrlKeys.titleBase], {
                    '{0}': skillSurveyTrls.vacancyFieldTitleProfession,
                })}
            </Title>
            {availableVacancyActions.edit && showEdit && (
                <>
                    {' '}
                    <Link
                        className={'resume-block-edit'}
                        data-qa={'vacancy-skills-survey-edit'}
                        to={`/skills_survey/employer?surveyProfession=${skillsSurveyProfession}&source=${
                            vacancyId || ''
                        }`}
                    >
                        <Text Element="span" size={TextSize.Small}>
                            {trls[TrlKeys.edit]}
                        </Text>
                    </Link>
                </>
            )}
            <VSpacing base={4} />
            <SkillsSurvey />
        </VacancySectionWrapper>
    );
};

export default translation(VacancySkillSurvey);
