import { DefaultRootState } from 'react-redux';

import { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import { UserType } from 'lux/models/userType';

const isXs = (breakpoint?: Breakpoint): boolean => breakpoint === Breakpoint.XS;

const isUserAnonymous = (state: DefaultRootState): boolean => state.userType === UserType.Anonymous;

const hasValidContactInfo = (state: DefaultRootState): boolean =>
    !!state.vacancyView.contactInfo && state.vacancyView.vacancyId !== undefined;

const isDifferentEmployer = (state: DefaultRootState): boolean =>
    state.userType === UserType.Employer && Number(state.employerId) !== state.vacancyView.company.id;

const checkRender = (state: DefaultRootState, breakpoint?: Breakpoint): boolean => {
    return hasValidContactInfo(state) && (!isXs(breakpoint) || !isUserAnonymous(state)) && !isDifferentEmployer(state);
};

export default checkRender;
