import classnames from 'classnames';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { ScheduleCamelCase } from 'lux/microcoreModels/schedule';
import { EmploymentType } from 'lux/models/vacancyView.types';
import { useSelector } from 'lux/modules/useSelector';

const TrlKeys = {
    employment: {
        [EmploymentType.Probation]: 'vacancy.employment.probation',
        [EmploymentType.Full]: 'vacancy.employment.full',
        [EmploymentType.Part]: 'vacancy.employment.part',
        [EmploymentType.Volunteer]: 'vacancy.employment.volunteer',
        [EmploymentType.Project]: 'vacancy.employment.project',
    },
    workSchedule: {
        [ScheduleCamelCase.Shift]: 'vacancy.workSchedule.shift',
        [ScheduleCamelCase.FullDay]: 'vacancy.workSchedule.fullDay',
        [ScheduleCamelCase.FlyInFlyOut]: 'vacancy.workSchedule.flyInFlyOut',
        [ScheduleCamelCase.Flexible]: 'vacancy.workSchedule.flexible',
        [ScheduleCamelCase.Remote]: 'vacancy.workSchedule.remote',
    },
};

interface VacancyEmploymentProps {
    isRedesign?: boolean;
}

const VacancyEmployment: TranslatedComponent<VacancyEmploymentProps> = ({ isRedesign, trls }) => {
    const employment = useSelector((state) => state.vacancyView.employment);
    const workSchedule = useSelector((state) => state.vacancyView['@workSchedule']);
    const type = employment?.['@type'];
    return (
        <p
            className={classnames({
                'vacancy-description-list-item': isRedesign,
            })}
            data-qa="vacancy-view-employment-mode"
        >
            {type && (
                <>
                    {trls[TrlKeys.employment[type]]}
                    {', '}
                </>
            )}
            {workSchedule && <span>{trls[TrlKeys.workSchedule[workSchedule]]}</span>}
        </p>
    );
};
export default translation(VacancyEmployment);
