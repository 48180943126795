import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import VacancyResponseLink from 'lux/components/VacancyResponseLink';
import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

const TrlKeys = {
    title: 'button.placeOtherResponse',
};

interface RespondButtonProps {
    unusedResumeIds?: string[];
    hiddenResumeIds?: string[];
    vacancyId: number;
}

const RespondButton: TranslatedComponent<RespondButtonProps> = ({
    trls,
    vacancyId,
    unusedResumeIds,
    hiddenResumeIds,
}) => {
    const { employerId } = useSelector((state) => state.vacancyView);
    if (!(unusedResumeIds?.length || hiddenResumeIds?.length)) {
        return null;
    }
    return (
        <VacancyResponseLink
            vacancyId={vacancyId}
            employerId={employerId}
            render={(onClick) => (
                <BlokoLink
                    Element={Link}
                    rel="nofollow"
                    to={`/applicant/vacancy_response?vacancyId=${vacancyId}${
                        employerId ? `&employerId=${employerId}` : ''
                    }`}
                    data-qa="vacancy-response-link-top-again"
                    onClick={onClick}
                >
                    {trls[TrlKeys.title]}
                </BlokoLink>
            )}
            place="vacancy_top_button"
        />
    );
};

export default translation(RespondButton);
