import classnames from 'classnames';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { PartTimeOption } from 'lux/models/vacancyView.types';
import { useSelector } from 'lux/modules/useSelector';
import { arrayToHumanReadableList } from 'lux/utils/humanReadableList';

const TrlKeys = {
    parttimeOptions: 'vacancy.view.parttime.options',
    or: 'or',
    options: {
        [PartTimeOption.Shifts]: 'vacancy.view.parttime.option.shifts',
        [PartTimeOption.Weekends]: 'vacancy.view.parttime.option.weekends',
        [PartTimeOption.Evenings]: 'vacancy.view.parttime.option.evenings',
    },
};

interface VacancyParttimeOptionsProps {
    isRedesign?: boolean;
}

const VacancyParttimeOptions: TranslatedComponent<VacancyParttimeOptionsProps> = ({ isRedesign, trls }) => {
    const parttimeOptions = useSelector((store) => store.vacancyView.parttimeOptions);
    const parttimeOptionsArray = Object.values(PartTimeOption).filter((parttimeKey) => {
        return parttimeOptions[parttimeKey];
    });
    if (!parttimeOptionsArray.length) {
        return null;
    }
    const parttimeOptionsArrayTrl = parttimeOptionsArray.map((parttimeKey) => {
        return trls[TrlKeys.options[parttimeKey]];
    });
    const parttimeOptionsTrl = arrayToHumanReadableList(parttimeOptionsArrayTrl, trls[TrlKeys.or]);
    return (
        <p
            className={classnames({
                'vacancy-description-list-item': isRedesign,
            })}
            data-qa="vacancy-view-parttime-options"
        >
            {trls[TrlKeys.parttimeOptions]}
            {': '}
            {parttimeOptionsTrl}
        </p>
    );
};

export default translation(VacancyParttimeOptions);
