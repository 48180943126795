import { FC, PropsWithChildren, useCallback, useContext, useEffect, useRef } from 'react';

import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import MainContentRefContext from 'lux/components/PageLayout/MainContentRefContext';

import styles from './vacancy-video.less';

const StickyContainer: FC<PropsWithChildren> = ({ children }) => {
    const stickyElementRef = useRef<HTMLDivElement>(null);
    const stickyPlaceRef = useRef<HTMLDivElement>(null);
    const dummyRef = useRef<HTMLDivElement>(null);

    const mainContentRef = useContext(MainContentRefContext);

    const breakpoint = useBreakpoint(Breakpoint.L);
    const isL = breakpoint === Breakpoint.L;

    const updateMetrics = useCallback(() => {
        if (
            !(isL && stickyElementRef.current && stickyPlaceRef.current && dummyRef.current && mainContentRef?.current)
        ) {
            return;
        }

        const mainContentOffsetHeight = mainContentRef.current.offsetHeight;
        const stickyElementBottom = stickyPlaceRef.current.offsetTop + stickyElementRef.current.offsetHeight;
        const diff = mainContentOffsetHeight - stickyElementBottom;

        dummyRef.current.style.height = `${diff}px`;
    }, [isL, mainContentRef]);

    useEffect(() => {
        updateMetrics();
        const intervalId = setInterval(updateMetrics, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [updateMetrics]);

    return (
        <>
            <div ref={stickyPlaceRef} />
            <div className={styles.stickyContainer} ref={stickyElementRef}>
                {children}
            </div>
            <div ref={dummyRef} />
        </>
    );
};

export default StickyContainer;
