import { FC, MutableRefObject, PropsWithChildren } from 'react';

import { Backplate, BackplateContentWrapper } from '@hh.ru/branding-packages';
import VSpacing from 'bloko/blocks/vSpacing';

import VacancyCompanyRedesigned from 'lux/components/VacancyView/VacancyCompanyRedesigned';
import VacancyVideo from 'lux/components/VacancyView/VacancyVideo';

interface SidebarProps {
    vacancyBodyFooterNodeRef: MutableRefObject<HTMLDivElement>;
}

const VideoVacancyWrapper: FC<PropsWithChildren> = ({ children }) => (
    <>
        <VSpacing base={7} xs={6} />
        <Backplate forceStylesBackplate dropShadow>
            <BackplateContentWrapper>{children}</BackplateContentWrapper>
        </Backplate>
    </>
);

const Sidebar: FC<SidebarProps> = ({ vacancyBodyFooterNodeRef }) => {
    return (
        <>
            <Backplate forceStylesBackplate dropShadow>
                <BackplateContentWrapper>
                    <VacancyCompanyRedesigned />
                </BackplateContentWrapper>
            </Backplate>
            <VacancyVideo vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef} Wrapper={VideoVacancyWrapper} />
        </>
    );
};
export default Sidebar;
