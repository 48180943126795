import { useState } from 'react';

import { UserType } from 'lux/models/userType';
import { useSelector } from 'lux/modules/useSelector';

export default () => {
    const userType = useSelector(({ userType }) => userType);
    const status = useSelector(({ vacancyView }) => vacancyView.status);

    const [isVisibleBody, setIsVisibleBody] = useState(
        [UserType.Employer, UserType.BackOffice].includes(userType) || (!status.archived && !status.disabled)
    );
    return { isVisibleBody, setIsVisibleBody };
};
