import { FC, PropsWithChildren } from 'react';

import { Text, VSpacing } from '@hh.ru/magritte-ui';
import Column, { ColumnsRow } from 'bloko/blocks/column';

import CONTENT_ID from 'lux/components/A11y/A11yConstants';
import AsyncHighlighter from 'lux/components/Markup/AsyncHighlighter';
import { useSelector } from 'lux/modules/useSelector';

import VacancyCompensation from 'lux/components/VacancyView/VacancyCompensation';

const Wrapper: FC<PropsWithChildren<{ hasWrapper?: boolean }>> = ({ hasWrapper, children }) =>
    hasWrapper ? (
        <ColumnsRow>
            <Column xs="4" s="8" m="8" l="10">
                {children}
            </Column>
        </ColumnsRow>
    ) : (
        <>{children}</>
    );

interface VacancyTitleProps {
    constructorBrandedVacancy?: boolean;
    hasWrapper?: boolean;
}

const VacancyTitle: FC<VacancyTitleProps> = ({ constructorBrandedVacancy, hasWrapper = true }) => {
    const status = useSelector(({ vacancyView }) => vacancyView.status);
    const name = useSelector(({ vacancyView }) => vacancyView.name);

    return (
        <Wrapper hasWrapper={hasWrapper}>
            <div className="vacancy-title" id={CONTENT_ID}>
                {constructorBrandedVacancy && <VSpacing default={16} />}
                <Text
                    data-qa="vacancy-title"
                    typography="title-4-semibold"
                    style={status.archived || status.disabled ? 'tertiary' : 'primary'}
                >
                    <AsyncHighlighter>{name}</AsyncHighlighter>
                </Text>
                <VSpacing default={12} />
                <VacancyCompensation isNeutral={status.archived || status.disabled} />
                <VSpacing default={24} />
            </div>
        </Wrapper>
    );
};

export default VacancyTitle;
