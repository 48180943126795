import { useState, FC } from 'react';

import { Tag } from '@hh.ru/magritte-ui';

import styles from './vacancy-skill-list.less';

interface VacancySkillListProps {
    list: string[];
    shouldBeShorted?: boolean;
}

const MAX_LIST_COUNT = 10;

const VacancySkillList: FC<VacancySkillListProps> = ({ list, shouldBeShorted = false }) => {
    const [shorted, setShorted] = useState<boolean>(shouldBeShorted && list.length > MAX_LIST_COUNT);
    const visibleList = shorted ? list.slice(0, MAX_LIST_COUNT) : list;
    return (
        <ul className={styles.vacancySkillList}>
            {visibleList.map((skill, index) => (
                <li data-qa="skills-element" key={index}>
                    <Tag>{skill}</Tag>
                </li>
            ))}
            {shorted && (
                <li onClick={() => setShorted(false)}>
                    <Tag>
                        <span className={styles.vacancySkillListMore}>...</span>
                    </Tag>
                </li>
            )}
        </ul>
    );
};

export default VacancySkillList;
