import Banners from 'HHC/Banners';
import visibilityWatcher from 'Utils/VisibilityWatcher';
/**
 * Подгружает баннер в ноду компонента, при видимости этой ноды.
 *
 * @param {String|Number}   id              ID баннера
 * @param {Array}           ids             ID баннеров, имеет приоритет перед id
 * @param {String}          [templateName]  Имя шаблона для баннера, если имя шаблона не указано, то баннер будет
 *                                          добавлен в текущую ноду без применения шаблонов
 *
 * @name HH/LoadBannerOnVisibleModule
 * @constructor
 */
export default (element, params) => {
    window.requestIdleCallback(() => {
        visibilityWatcher.init({
            element,
            onVisible() {
                const { ids, id, templateName, adfoxParams } = params;
                let banners = [id];
                if (ids) {
                    banners = ids;
                }
                Banners.create(element, banners, templateName, adfoxParams);
            },
        });
    });
};
