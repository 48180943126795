import { PropsWithChildren, FC, RefObject } from 'react';

import VacancyAcceptTemporary from 'lux/components/VacancyView/VacancyAcceptTemporary';
import VacancyActions from 'lux/components/VacancyView/VacancyActions';
import VacancyCompany from 'lux/components/VacancyView/VacancyCompany';
import VacancyEmployment from 'lux/components/VacancyView/VacancyEmployment';
import VacancyOnlineUsersCounter from 'lux/components/VacancyView/VacancyOnlineUsersCounter';
import VacancyParttimeOptions from 'lux/components/VacancyView/VacancyParttimeOptions';
import VacancyTitle from 'lux/components/VacancyView/VacancyTitle';
import VacancyWorkExperience from 'lux/components/VacancyView/VacancyWorkExperience';

interface EmployerViewTopProps {
    resumeFormNodeRef: RefObject<HTMLDivElement>;
    contactsNodeRef: RefObject<HTMLDivElement>;
    vacancyBodyFooterNodeRef: RefObject<HTMLDivElement>;
    hasWrapper?: boolean;
}

const EmployerViewTop: FC<EmployerViewTopProps & PropsWithChildren> = ({
    contactsNodeRef,
    vacancyBodyFooterNodeRef,
    resumeFormNodeRef,
    hasWrapper,
}) => {
    return (
        <>
            <VacancyTitle hasWrapper={hasWrapper} />
            <VacancyCompany hasWrapper={hasWrapper} />
            <VacancyOnlineUsersCounter />
            <VacancyActions
                contactsNodeRef={contactsNodeRef}
                vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                resumeFormNodeRef={resumeFormNodeRef}
                hasWrapper={hasWrapper}
            />
            <VacancyWorkExperience />
            <VacancyEmployment />
            <VacancyAcceptTemporary />
            <VacancyParttimeOptions />
        </>
    );
};

export default EmployerViewTop;
