import { useParams } from 'react-router-dom';

import { useSelector } from 'lux/modules/useSelector';
import VACANCY_ACTIONS from 'lux/modules/vacancyActions';

const usePrintVacancy = (): [number | null, boolean, boolean] => {
    const isCreate = useSelector(
        ({ vacancyAction }) =>
            vacancyAction === VACANCY_ACTIONS.CREATE || vacancyAction === VACANCY_ACTIONS.CREATE_FROM_DRAFT
    );
    const vacancyCreateDraftId = useSelector((state) => state.vacancyCreateDraftId);
    const {
        vacancyId,
        originalVacancyId,
        archivedVacancyId,
        draftId,
    }: { vacancyId?: string; originalVacancyId?: string; archivedVacancyId?: string; draftId?: string } = useParams();

    const id =
        vacancyCreateDraftId ||
        parseInt(vacancyId || originalVacancyId || archivedVacancyId || draftId || '', 10) ||
        null;
    const show = isCreate && !!id;
    const isDraft = !!vacancyCreateDraftId;

    return [id, isDraft, show];
};

export default usePrintVacancy;
