import { ReactNode, RefObject, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import Button, { ButtonKind, ButtonAppearance, ButtonScale } from 'bloko/blocks/button';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import SendFloodlightConversion from 'HH/SendFloodlightConversion';
import scrollToElement from 'Utils/ScrollToElement';
import translation from 'lux/components/translation';
import { UserType } from 'lux/models/userType';
import { vacancySetVisibilityContactsAction } from 'lux/models/vacancyView';
import { useSelector } from 'lux/modules/useSelector';

import VacancyContactsDrop from 'lux/components/VacancyView/VacancyContactsDrop';
import useContactsAnalytics from 'lux/components/VacancyView/hooks/useContactsAnalytics';

const TrlKeys = {
    showContacts: 'vacancy.action.showContacts',
    showContactsCompact: 'vacancy.action.showContacts.compact',
};

const SCROLL_TOP_OFFSET = -25;

interface VacancyActionShowContactsProps {
    contactsNodeRef?: RefObject<HTMLDivElement>;
    vacancyBodyFooterNodeRef?: RefObject<HTMLDivElement>;
    constructorBrandedVacancy?: boolean;
    isRedesign?: boolean;
}

const VacancyActionShowContacts: TranslatedComponent<VacancyActionShowContactsProps> = ({
    contactsNodeRef,
    vacancyBodyFooterNodeRef,
    isRedesign,
    constructorBrandedVacancy,
    trls,
}) => {
    const contactInfo = useSelector((state) => state.vacancyView.contactInfo);
    const isVisible = useSelector((state) => state.vacancyView.isVisibleContacts);
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const employerId = useSelector((state) => state.vacancyView.company.id);
    const userType = useSelector((state) => state.userType);
    const dispatch = useDispatch();
    const sendViewContactsAnalytics = useContactsAnalytics({
        place: 'vacancy_top_button',
    });
    const breakpoint = useBreakpoint();
    const isXs = breakpoint === Breakpoint.XS;
    const isAnonymousOnXs = userType === UserType.Anonymous && isXs;
    const isBrandingPreview = useSelector((state) => state.vacancyView.isBrandingPreview);

    const showContactsHandler = useCallback(() => {
        dispatch(vacancySetVisibilityContactsAction(true));
        sendViewContactsAnalytics({
            vacancyId,
            employerId,
            userType,
        });
        SendFloodlightConversion({
            type: 'hh_co0',
            cat: 'hh_bu0',
        });
        const contactsElement = contactsNodeRef?.current;
        if (contactsElement && !isRedesign) {
            scrollToElement(contactsElement, {
                topOffset: SCROLL_TOP_OFFSET,
                centered: false,
            });
        }
    }, [dispatch, sendViewContactsAnalytics, vacancyId, employerId, userType, contactsNodeRef, isRedesign]);
    const getKind = () => {
        if (constructorBrandedVacancy) {
            return ButtonKind.Inversed;
        }
        if (isRedesign) {
            return ButtonKind.Success;
        }
        return undefined;
    };
    const renderContactsButton = () => (
        <Button
            appearance={ButtonAppearance.Outlined}
            kind={getKind()}
            scale={isRedesign ? ButtonScale.Large : undefined}
            stretched
            disabled={isBrandingPreview}
            onClick={isBrandingPreview ? undefined : showContactsHandler}
            data-qa="show-employer-contacts show-employer-contacts_top-button"
        >
            {trls[isXs ? TrlKeys.showContactsCompact : TrlKeys.showContacts]}
        </Button>
    );

    const renderWrapper = (content: ReactNode) => (
        <div
            className={classnames('vacancy-action vacancy-action_stretched', {
                'vacancy-action_stretched-redesigned': isRedesign,
            })}
        >
            {content}
        </div>
    );

    if (isBrandingPreview) {
        return renderWrapper(renderContactsButton());
    }

    if (!contactInfo || isAnonymousOnXs) {
        return null;
    }

    return renderWrapper(
        isRedesign ? (
            <VacancyContactsDrop show={isVisible} vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef} place="top">
                {renderContactsButton()}
            </VacancyContactsDrop>
        ) : (
            renderContactsButton()
        )
    );
};

export default translation(VacancyActionShowContacts);
