import { FC, RefObject, useEffect, useState } from 'react';

import DropDown from 'bloko/blocks/drop/Down/Click';

import { VacancyResponsePopupResponse } from 'lux/components/VacancyResponseLink';
import usePrintDetect from 'lux/hooks/usePrintDetect';
import { UserType } from 'lux/models/userType';
import { useSelector } from 'lux/modules/useSelector';
import fetchResponseType from 'lux/requests/contacts/fetchResponseType';

import VacancyContactsDropContent from 'lux/components/VacancyView/VacancyContactsDropContent';

interface VacancyContactsDropProps {
    vacancyBodyFooterNodeRef?: RefObject<HTMLDivElement>;
    children: JSX.Element;
    show?: boolean;
    place: string;
}

const VacancyContactsDrop: FC<VacancyContactsDropProps> = ({ vacancyBodyFooterNodeRef, show, children, place }) => {
    const [chatButtonInfo, setChatButtonInfo] = useState<VacancyResponsePopupResponse | null>(null);
    const [chatButtonFetching, setChatButtonFetching] = useState(true);
    const userType = useSelector((state) => state.userType);
    const { vacancyId, contactInfo, address } = useSelector((state) => state.vacancyView);
    const employerId = useSelector((state) => state.vacancyView.company.id);
    const chatWritePossibility = useSelector((state) =>
        vacancyId ? state.applicantVacancyResponseStatuses[vacancyId]?.shortVacancy?.chatWritePossibility : undefined
    );
    const isPrinting = usePrintDetect();
    const isAnonymous = userType === UserType.Anonymous;

    useEffect(() => {
        const fetchData = async () => {
            setChatButtonFetching(true);
            if (typeof vacancyId !== 'number' || userType === UserType.Employer) {
                setChatButtonFetching(false);
                return;
            }
            try {
                const chatInfo = await fetchResponseType(vacancyId);
                setChatButtonInfo(chatInfo);
            } finally {
                setChatButtonFetching(false);
            }
        };

        if (!isAnonymous) {
            void fetchData();
        } else {
            setChatButtonFetching(false);
        }
    }, [isAnonymous, userType, vacancyId]);

    if (typeof vacancyId !== 'number') {
        return null;
    }

    if (isPrinting) {
        if (!show || place === 'top') {
            return children;
        }
        return (
            <VacancyContactsDropContent
                loading={chatButtonFetching}
                vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                vacancyId={vacancyId}
                contactInfo={contactInfo}
                isAnonymous={isAnonymous}
            />
        );
    }

    return (
        <DropDown
            show={show}
            showCloseButton={!contactInfo?.callTrackingEnabled}
            flexible
            render={(closeDrop) => (
                <VacancyContactsDropContent
                    chatButtonInfo={chatButtonInfo}
                    loading={chatButtonFetching}
                    onClose={closeDrop}
                    address={address ? address.displayName : undefined}
                    vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                    vacancyId={vacancyId}
                    contactInfo={contactInfo}
                    isAnonymous={isAnonymous}
                    employerId={employerId}
                    chatWritePossibility={chatWritePossibility}
                />
            )}
        >
            {children}
        </DropDown>
    );
};

export default VacancyContactsDrop;
