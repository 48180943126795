import { FC, RefObject } from 'react';
import { DefaultRootState } from 'react-redux';

import Column from 'bloko/blocks/column';
import Gap from 'bloko/blocks/gap';

import { TopLevelSite } from 'lux/models/topLevelSite';
import { UserType } from 'lux/models/userType';
import { useSelector } from 'lux/modules/useSelector';

import VacancyResponse from 'lux/components/VacancyView/VacancyResponse';

export const checkVacancyApplicantResponseRender = (state: DefaultRootState): boolean => {
    if (![UserType.Applicant, UserType.NedoUser].includes(state.userType)) {
        return false;
    }

    const vacancyId = state.vacancyView?.vacancyId;
    const responseStatus = vacancyId ? state.applicantVacancyResponseStatuses?.[vacancyId] : undefined;
    const alreadyResponded = (responseStatus?.usedResumeIds?.length || 0) > 0;

    return !state.vacancyView.showResumeForm && !alreadyResponded;
};

interface VacancyApplicantResponseProps {
    vacancyBodyFooterNodeRef: RefObject<HTMLDivElement>;
    isRedesign: boolean;
}

const VacancyApplicantResponse: FC<VacancyApplicantResponseProps> = ({ vacancyBodyFooterNodeRef, isRedesign }) => {
    const showResponse = useSelector(checkVacancyApplicantResponseRender);
    const topLevelSite = useSelector((store) => store.topLevelSite);

    if (!showResponse) {
        return null;
    }

    const showNewFooter = topLevelSite === TopLevelSite.RU || topLevelSite === TopLevelSite.BY;

    return (
        <div className="noprint">
            <Column xs="4" s={showNewFooter ? '7' : '8'} m={showNewFooter ? '7' : '8'} l={showNewFooter ? '7' : '10'}>
                <Gap top>
                    <div className="vacancy-actions vacancy-actions_applicant">
                        <VacancyResponse
                            vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                            hideResponded={showNewFooter}
                            isRedesign={isRedesign}
                        />
                    </div>
                </Gap>
            </Column>
        </div>
    );
};

export default VacancyApplicantResponse;
