import { FC, useRef } from 'react';

import { Text, TooltipHover } from '@hh.ru/magritte-ui';
import { QuestionCircleOutlinedSize16, UserDoubleOutlinedSize16 } from '@hh.ru/magritte-ui/icon';

import { NON_BREAKING_SPACE } from 'lux/modules/symbols';

const VacancyAdditionalAccept: FC<Record<'icon' | 'text' | 'hint', string>> = ({ icon, text, hint }) => {
    const activatorRef = useRef<HTMLSpanElement>(null);
    return (
        <div className="vacancy-icon-wrapper">
            <span className={`vacancy-icon vacancy-icon_${icon}`}>
                {icon === 'kids' && <UserDoubleOutlinedSize16 />}
                {icon === 'handicapped' && <span className="accept-handicapped-icon" />}
            </span>
            <Text Element="span" typography="label-2-regular">
                {text}
                {NON_BREAKING_SPACE}
                <span className="vacancy-noprint">
                    <span ref={activatorRef}>
                        <QuestionCircleOutlinedSize16 />
                    </span>
                    <TooltipHover direction="top" alignment="center" activatorRef={activatorRef}>
                        {hint}
                    </TooltipHover>
                </span>
            </Text>
        </div>
    );
};

export default VacancyAdditionalAccept;
