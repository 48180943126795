import { forwardRef } from 'react';

import { isExternalBannersRestricted } from 'HHC/Banners';
import { useIsBelarus } from 'lux/hooks/useCountries';
import useFeatureEnabled from 'lux/hooks/useFeatureEnabled';
import { AdsExternalBannerProps } from 'lux/models/banners/AdsExternalBanner.types';

import Banner from 'lux/components/Banner';

const Features = {
    enableADVWithoutCookieAccept: 'enable_adv_without_cookie_accept',
};

const AdsExternalBanner = forwardRef<HTMLDivElement, AdsExternalBannerProps>(
    ({ id, cId, pp, p2, ...adfoxParams }, ref) => {
        const isADVWithoutCookieAcceptEnabled = useFeatureEnabled(Features.enableADVWithoutCookieAccept);
        const enableAdfox = pp && p2;
        const shouldHideExternalBanners = isExternalBannersRestricted(useIsBelarus());

        if (!enableAdfox) {
            return (
                <Banner
                    key={cId}
                    id={id}
                    ref={ref}
                    isEmpty={shouldHideExternalBanners && !isADVWithoutCookieAcceptEnabled}
                />
            );
        }

        return (
            <Banner
                key={cId}
                id={id}
                templateName=""
                adfoxParams={{
                    pp,
                    p2,
                    cId,
                    shouldHideExternalBanners,
                    ...adfoxParams,
                }}
            >
                <div
                    ref={ref}
                    id={cId}
                    className={`HHC-Banner-${id} HH-Banner-Adfox banner-place banner-place_${id} banner-place-adfox banner-place-adfox_${id}`}
                    data-banner-id={id}
                    data-empty-class="banner-place_hide"
                    data-loaded-class="banner-place_show"
                />
            </Banner>
        );
    }
);

export default AdsExternalBanner;
