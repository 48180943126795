import { DefaultRootState } from 'react-redux';

import { Title } from '@hh.ru/magritte-ui';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

import VacancySectionWrapper from 'lux/components/VacancyView/VacancySectionWrapper';

export const checkVacancyDriverLicenseRender = ({ vacancyView }: DefaultRootState): boolean =>
    !!vacancyView.driverLicenseTypes?.driverLicenseType?.length;

const TrlKeys = {
    driverExperience: 'vacancy.view.driverExperience',
    driverLicense: 'vacancy.view.driverLicense',
};

interface VacancyDriverLicenseProps {
    hasVacancySectionWrapper?: boolean;
}

const VacancyDriverLicense: TranslatedComponent<VacancyDriverLicenseProps> = ({
    hasVacancySectionWrapper = true,
    trls,
}) => {
    const driverLicenseTypes = useSelector(({ vacancyView }) => vacancyView.driverLicenseTypes);
    const showDriverLicense = useSelector(checkVacancyDriverLicenseRender);

    if (!driverLicenseTypes?.driverLicenseType || !showDriverLicense) {
        return null;
    }

    return (
        <VacancySectionWrapper hasWrapper={hasVacancySectionWrapper}>
            <Title Element="h2" size="extra-small">
                {trls[TrlKeys.driverExperience]}
            </Title>
            <VSpacing base={4} />
            {trls[TrlKeys.driverLicense]} {driverLicenseTypes.driverLicenseType.join(', ')}
        </VacancySectionWrapper>
    );
};

export default translation(VacancyDriverLicense);
