import { useState, useCallback, useEffect, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Link as MagritteLink } from '@hh.ru/magritte-ui';
import Down, { DownPlacement } from 'bloko/blocks/drop/Down';
import Link, { LinkAppearance } from 'bloko/blocks/link';

import RegistrationAction from 'lux/components/VacancyActions/RegistrationAction';
import translation from 'lux/components/translation';
import usePrintDetect from 'lux/hooks/usePrintDetect';
import { UserType } from 'lux/models/userType';
import { vacancySetVisibilityContactsAction } from 'lux/models/vacancyView';
import { useSelector } from 'lux/modules/useSelector';

import useContactsAnalytics from 'lux/components/VacancyView/hooks/useContactsAnalytics';

const VacancyContactsLinkSwitch = ({ trls, isMagritte }) => {
    const userType = useSelector((state) => state.userType);
    const isPrinting = usePrintDetect();
    const {
        isVisibleContacts,
        vacancyId,
        company: { id: employerId },
    } = useSelector((state) => state.vacancyView);
    const [isDropVisible, setDropVisible] = useState(isVisibleContacts);
    const dispatch = useDispatch();
    const sendViewContactsAnalytics = useContactsAnalytics({ place: 'vacancy_bottom_button' });
    const isAnonymous = userType === UserType.Anonymous;

    useEffect(() => {
        if (isAnonymous) {
            setDropVisible(isVisibleContacts);
        }
    }, [isVisibleContacts, isAnonymous]);

    const handleOpen = useCallback(() => {
        dispatch(vacancySetVisibilityContactsAction(true));
        sendViewContactsAnalytics({ vacancyId, employerId, userType });
    }, [dispatch, employerId, sendViewContactsAnalytics, userType, vacancyId]);

    const handleClose = useCallback(() => {
        dispatch(vacancySetVisibilityContactsAction(false));
    }, [dispatch]);

    useLayoutEffect(() => {
        if (isPrinting) {
            handleOpen();
        }
    }, [handleOpen, isPrinting]);

    const linkSwitch = isMagritte ? (
        <MagritteLink onClick={handleOpen} data-qa="show-employer-contacts" typography="subtitle-1-semibold">
            {trls[VacancyContactsLinkSwitch.trls.contacts]}
        </MagritteLink>
    ) : (
        <Link appearance={LinkAppearance.Pseudo} onClick={handleOpen} data-qa="show-employer-contacts">
            {trls[VacancyContactsLinkSwitch.trls.contacts]}
        </Link>
    );

    return isAnonymous ? (
        <Down
            showCloseButton
            show={isDropVisible}
            onClose={handleClose}
            render={() => <RegistrationAction />}
            placement={DownPlacement.BottomStart}
        >
            {linkSwitch}
        </Down>
    ) : (
        linkSwitch
    );
};

VacancyContactsLinkSwitch.propTypes = {
    trls: PropTypes.object,
    isMagritte: PropTypes.bool,
};

VacancyContactsLinkSwitch.trls = {
    contacts: 'vacancy.contactInfo.opened',
};

export default translation(VacancyContactsLinkSwitch);
