import { Fragment } from 'react';
import classnames from 'classnames';

import vacancyToChatButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/vacancy_to_chat_button_click';
import { useChatik } from '@hh.ru/chatik-integration';
import Button, { ButtonAppearance, ButtonKind, ButtonScale } from 'bloko/blocks/button';
import { BubbleScaleSmallKindSingleAppearanceOutlinedShapeRound, IconColor, IconDynamic } from 'bloko/blocks/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

interface VacancyActionChatProps {
    isRedesign?: boolean;
    stretched?: boolean;
}

const TrlKeys = {
    hint: 'hint.chat.open',
    text: 'vacancy.contacts.chat.button',
};

const VacancyActionChat: TranslatedComponent<VacancyActionChatProps> = ({ trls, isRedesign, stretched }) => {
    const { openChatik } = useChatik();
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const chatId = useSelector((state) => state.vacancyView.chat?.id);

    const commonProps = {
        'data-qa': 'vacancy__question-chat-open',
        onClick: () => {
            if (chatId && vacancyId) {
                vacancyToChatButtonClick({ vacancyId });
                openChatik?.({ chatId });
            }
        },
        title: trls[TrlKeys.hint],
    };

    const Wrapper = stretched ? Fragment : IconDynamic;

    const renderRedesignChatButton = () =>
        stretched ? (
            <Button
                appearance={ButtonAppearance.Outlined}
                kind={ButtonKind.Success}
                scale={ButtonScale.Large}
                stretched
                {...commonProps}
            >
                {trls[TrlKeys.text]}
            </Button>
        ) : (
            <button
                className="vacancy-action-bubble-redesign vacancy-action-bubble-redesign_appearance-outlined"
                {...commonProps}
            >
                {stretched ? trls[TrlKeys.text] : <span className="vacancy-action-bubble-icon-redesign" />}
            </button>
        );

    return (
        <span className={classnames('vacancy-action', { 'vacancy-action_stretched': stretched })}>
            {isRedesign ? (
                renderRedesignChatButton()
            ) : (
                <Wrapper>
                    <Button
                        appearance={ButtonAppearance.Outlined}
                        stretched={stretched}
                        icon={
                            stretched ? undefined : (
                                <BubbleScaleSmallKindSingleAppearanceOutlinedShapeRound initial={IconColor.Gray50} />
                            )
                        }
                        {...commonProps}
                    >
                        {stretched ? trls[TrlKeys.text] : undefined}
                    </Button>
                </Wrapper>
            )}
        </span>
    );
};

export default translation(VacancyActionChat);
