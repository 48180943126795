import { RefObject, FC } from 'react';
import { DefaultRootState } from 'react-redux';

import { ColumnsRow } from 'bloko/blocks/column';

import VacancyAnonymousResponse, {
    checkVacancyAnonymousResponseRender,
} from 'lux/components/VacancyView/VacancyAnonymousResponse';
import VacancyApplicantResponse, {
    checkVacancyApplicantResponseRender,
} from 'lux/components/VacancyView/VacancyApplicantResponse';

export const checkBetweenSectionsRender = (state: DefaultRootState): boolean =>
    checkVacancyAnonymousResponseRender(state) || checkVacancyApplicantResponseRender(state);

export interface BetweenSectionsProps {
    vacancyBodyFooterNodeRef: RefObject<HTMLDivElement>;
    isRedesign: boolean;
}

const BetweenSections: FC<BetweenSectionsProps> = ({ vacancyBodyFooterNodeRef, isRedesign }) => {
    return (
        <ColumnsRow>
            <VacancyAnonymousResponse isRedesign={isRedesign} />
            <VacancyApplicantResponse vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef} isRedesign={isRedesign} />
        </ColumnsRow>
    );
};

export default BetweenSections;
