import { Text } from '@hh.ru/magritte-ui';
import { ClipOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import useIsVisibleUserTest from 'lux/components/VacancyView/hooks/useIsVisibleUserTest';
import translation from 'lux/components/translation';

const TrlKeys = {
    test: 'vacancy.has.test',
};

const VacancyHasTestText: TranslatedComponent = ({ trls }) => {
    const isVisibleUserTest = useIsVisibleUserTest();

    if (!isVisibleUserTest) {
        return null;
    }

    return (
        <div className="vacancy-icon-wrapper" data-qa="vacancy-view-user-test">
            <div className="vacancy-icon">
                <ClipOutlinedSize16 />
            </div>
            <Text Element="span" typography="label-2-regular">
                {trls[TrlKeys.test]}
            </Text>
        </div>
    );
};

export default translation(VacancyHasTestText);
