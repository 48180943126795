import { MutableRefObject, useState } from 'react';
import { DefaultRootState } from 'react-redux';
import classnames from 'classnames';

import { H2 } from 'bloko/blocks/header';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import translation from 'lux/components/translation';
import { UserType } from 'lux/models/userType';
import { useSelector } from 'lux/modules/useSelector';

import QuestionsInput from 'lux/components/VacancyView/VacancyResponseQuestions/QuestionsInput';
import Suggest from 'lux/components/VacancyView/VacancyResponseQuestions/Suggest';

import styles from './VacancyResponseQuestions.less';

export const checkVacancyResponseQuestionsRender = (state: DefaultRootState): boolean => {
    const isNeededUserType = [UserType.Applicant, UserType.Anonymous, UserType.NedoUser].includes(state.userType);
    const vacancyId = state.vacancyView.vacancyId;
    const responseStatus = vacancyId ? state.applicantVacancyResponseStatuses[vacancyId] : null;
    const isCurrentlyApplied = responseStatus && responseStatus.usedResumeIds?.length > 0;
    return !isCurrentlyApplied && isNeededUserType && vacancyId !== undefined;
};

interface VacancyResponseQuestionsProps {
    vacancyBodyFooterNodeRef: MutableRefObject<HTMLDivElement>;
    type?: 'question' | 'response';
    hasBottomGap?: boolean;
    hasSuggest?: boolean;
}

const TrlKeys = {
    responseTitle: 'vacancy.view.responseQuestions.title',
    questionTitle: 'vacancy.view.responseQuestions.questionTitle',
    questionDescription: 'vacancy.view.responseQuestions.questionDescription',
    subTitle: 'vacancy.view.responseQuestions.subTitle',
    placeholder: 'vacancy.view.responseQuestions.greeting',
};

const VacancyResponseQuestions: TranslatedComponent<VacancyResponseQuestionsProps> = ({
    trls,
    vacancyBodyFooterNodeRef,
    type = 'response',
    hasBottomGap = true,
    hasSuggest = type === 'response',
}) => {
    const [text, setText] = useState(`${trls[TrlKeys.placeholder]} `);

    const topLevelDomain = useSelector(({ topLevelDomain }) => topLevelDomain || '');
    const vacancyId = useSelector(({ vacancyView }) => vacancyView.vacancyId);
    const applicantInfo = useSelector((state) => state.applicantInfo);
    const show = useSelector(checkVacancyResponseQuestionsRender);
    const hasHiddenResumes = Boolean(applicantInfo?.visible !== applicantInfo?.total);

    if (!show || typeof vacancyId !== 'number') {
        return null;
    }

    const suggest =
        type === 'response' || hasSuggest ? (
            <Suggest
                vacancyId={vacancyId}
                vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                type={type}
                onClick={setText}
            />
        ) : null;

    return (
        <div
            className={classnames(styles.vacancyResponseQuestions, {
                [styles.vacancyResponseQuestionsTypeResponse]: type === 'response',
                [styles.vacancyResponseQuestionsTypeQuestion]: type === 'question',
                [styles.vacancyResponseQuestionsWithSuggest]: Boolean(suggest),
                [styles.vacancyResponseQuestionsBottomGap]: hasBottomGap,
            })}
        >
            <H2>
                {type === 'response'
                    ? trls[TrlKeys.responseTitle]
                    : format(trls[TrlKeys.questionTitle], { '{0}': topLevelDomain })}
            </H2>
            {type === 'question' && hasHiddenResumes ? (
                <Text size={TextSize.Large} importance={TextImportance.Secondary}>
                    {trls[TrlKeys.questionDescription]}
                </Text>
            ) : null}
            <VSpacing base={type === 'response' ? 2 : 4} />

            {type === 'response' ? (
                <>
                    <Text size={TextSize.Large}>{trls[TrlKeys.subTitle]}</Text>
                    <VSpacing base={4} />
                    {suggest}
                </>
            ) : (
                <QuestionsInput value={text} onChange={setText} suggest={suggest} vacancyId={vacancyId} />
            )}
        </div>
    );
};

export default translation(VacancyResponseQuestions);
