import { useMemo, useEffect, SetStateAction, Dispatch, PropsWithChildren, FC } from 'react';

import Column from 'bloko/blocks/column';
import { H2 } from 'bloko/blocks/header';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { UserType } from 'lux/models/userType';
import { useSelector } from 'lux/modules/useSelector';

const Wrapper: FC<PropsWithChildren<{ hasWrapper?: boolean }>> = ({ hasWrapper, children }) =>
    hasWrapper ? (
        <Column xs="4" s="8" m="8" l="10">
            <div className="vacancy-section">{children}</div>
        </Column>
    ) : (
        <>{children}</>
    );

const TrlKeys = {
    title: 'vacancy.view.archived.title',
    removed: 'employer.vacancyesArchive.removed',
    description: 'vacancy.view.archived.description',
    showMore: 'vacancy.view.archived.action',
};

interface VacancyIsArchivedProps {
    isHiddenSwitcher?: boolean;
    setVisibilityBody: Dispatch<SetStateAction<boolean>>;
    hasVacancySectionWrapper?: boolean;
}

const VacancyIsArchived: TranslatedComponent<VacancyIsArchivedProps> = ({
    isHiddenSwitcher,
    setVisibilityBody,
    hasVacancySectionWrapper = true,
    trls,
}) => {
    const status = useSelector(({ vacancyView }) => vacancyView.status);
    const userType = useSelector(({ userType }) => userType);
    const availableVacancyActions = useSelector((state) => state.availableVacancyActions);
    const archivedTitle = useMemo(() => {
        if ((status.archived && availableVacancyActions.restoreToArchive) || status.disabled) {
            return trls[TrlKeys.removed];
        }
        if (status.archived) {
            return trls[TrlKeys.title];
        }
        return null;
    }, [status.archived, status.disabled, trls, availableVacancyActions]);

    useEffect(() => {
        // В случае, если у нас вакансия архивная,
        // то нам нужно спрятать контент после его инициализации? чтобы код подрядчиков работал
        // useEffect как раз будет работать после DOMContentLoaded и прочего
        const el = document.querySelector('.HH-VacancyBrandedDescription-DANGEROUS-HTML-ARCHIVED');
        if (!el) {
            return;
        }
        el.classList.add('g-hidden');
    }, []);

    useEffect(() => {
        /**
         * На странице присутствует 2 виджета отзывов работодателя:
         * - однострочный верхний (выводится под названием компании)
         * - большой подробный (выводится в блоке VacancyFooter)
         *
         * При клике на количество отзывов верхнего виджета страница прокручивается до большого
         * Так как виджеты это независимые прокси-компоненты — действие осуществляется засчет
         * диспатча кастомного события 'HH-Dream-Job-Scroll-To-Big-Widget' на window
         *
         * Само событие ловится в компоненте вывода прокси-кмпонента большого виджета и осуществляется прокрутка
         *
         * На архивной вакансии блок VacancyBody, включая VacancyFooter, скрыт под катом
         * Необходимо чтобы при клике на количество отзывов тело вакансии раскрывалось и экран
         * прокрутился до большого виджета с подробной информацией об отзывах
         *
         * В этом компоненте ловим необходимое событие и раскрываем тело вакансии
         * Далее нужно чтобы компонент нижнего виджета получил это событие и прокрутил экран до нужного элемента
         * По этому диспатчим это событие ещё раз, предварительно сняв текущий обработчик во избежание зацикливания
         */
        const handler = () => {
            if (!isHiddenSwitcher) {
                setVisibilityBody(true);
                window.removeEventListener('HH-Dream-Job-Scroll-To-Big-Widget', handler);
                window.dispatchEvent(new CustomEvent('HH-Dream-Job-Scroll-To-Big-Widget'));
            }
        };
        window.addEventListener('HH-Dream-Job-Scroll-To-Big-Widget', handler);
        return () => window.removeEventListener('HH-Dream-Job-Scroll-To-Big-Widget', handler);
    }, [isHiddenSwitcher, setVisibilityBody]);

    if (!status.archived && !status.disabled) {
        return null;
    }
    return (
        <Wrapper hasWrapper={hasVacancySectionWrapper}>
            <H2>{archivedTitle}</H2>
            <VSpacing base={4} />
            <p className="vacancy-archive-description">{trls[TrlKeys.description]}</p>
            {!isHiddenSwitcher && userType !== UserType.Employer && userType !== UserType.BackOffice && (
                <Link appearance={LinkAppearance.Pseudo} onClick={() => setVisibilityBody(true)}>
                    {trls[TrlKeys.showMore]}
                </Link>
            )}
        </Wrapper>
    );
};

export default translation(VacancyIsArchived);
