import { useSelector } from 'lux/modules/useSelector';

interface Logo {
    '@type': string;
    '@url': string;
}

const useCompanyLogo = (): Logo | undefined => {
    const logos = useSelector((state) => state.vacancyView.company.logos);
    return (
        logos?.logo.find((logo) => logo['@type'] === 'customVacancyLogo') ||
        logos?.logo.find((logo) => logo['@type'] === 'vacancyPage')
    );
};

export default useCompanyLogo;
