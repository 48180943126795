import { ComponentType, FC, Fragment, memo, PropsWithChildren, RefObject, useState } from 'react';

import videoVacancyCardButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/video_vacancy_card_button_click';
import videoVacancyCloseButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/video_vacancy_close_button_click';
import videoVacancyPauseButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/video_vacancy_pause_button_click';
import videoVacancyPlayButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/video_vacancy_play_button_click';
import videoVacancyRespondButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/video_vacancy_respond_button_click';
import { H2Section } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { useIsRussia } from 'lux/hooks/useCountries';
import { useIsZarplataPlatform } from 'lux/hooks/usePlatform';
import { UserType } from 'lux/models/userType';
import { BrandingType } from 'lux/models/vacancyView';
import { useSelector } from 'lux/modules/useSelector';

import FakePlayer from 'lux/components/VacancyView/VacancyVideo/FakePlayer';
import StickyContainer from 'lux/components/VacancyView/VacancyVideo/StickyContainer';
import VacancyVideoModal from 'lux/components/VacancyView/VacancyVideo/VacancyVideoModal';

import styles from './vacancy-video.less';

interface VacancyVideoProps {
    vacancyBodyFooterNodeRef?: RefObject<HTMLDivElement>;
    Wrapper?: ComponentType;
}

const DefaultWrapper: FC<PropsWithChildren> = ({ children }) => (
    <>
        <VSpacing base={7} xs={6} />
        <div className={styles.root}>{children}</div>
    </>
);

const TrlKeys = {
    title: 'applicant.videoVacancy.block.title',
};

const MAX_VOLUME = 100;

const VacancyVideo: TranslatedComponent<VacancyVideoProps> = ({
    vacancyBodyFooterNodeRef,
    Wrapper = DefaultWrapper,
    trls,
}) => {
    const [visible, setVisible] = useState(false);
    const isRussia = useIsRussia();
    const isZp = useIsZarplataPlatform();
    const isApplicant = useSelector(({ userType }) => userType === UserType.Applicant);
    const isAnonymous = useSelector(({ userType }) => userType === UserType.Anonymous);
    const videoVacancyUrl = useSelector((state) => state.vacancyView.videoVacancyUrl);

    const needShow = isRussia && (isApplicant || isAnonymous) && !isZp && !!videoVacancyUrl;

    const [currentTime, setCurrentTime] = useState(0);
    const [volume, setVolume] = useState(MAX_VOLUME);
    const [duration, setDuration] = useState(0);

    const vacancyId = useSelector((state) => state.vacancyView.vacancyId) || 0;
    const brandType = useSelector((state) => state.vacancyView.brandingType) || '';
    const isSticky = [BrandingType.Constructor, BrandingType.Chameleon].includes(brandType);
    const Container = isSticky ? StickyContainer : Fragment;

    const analyticsParams = { vacancyId, brandType, currentTime, volume, videoLength: duration };

    if (!needShow) {
        return null;
    }

    return (
        <Container>
            <Wrapper>
                <H2Section lite>{trls[TrlKeys.title]}</H2Section>
                <VSpacing base={6} xs={4} />
                <FakePlayer
                    onClick={() => {
                        setVisible(true);
                        videoVacancyCardButtonClick({ vacancyId, brandType });
                    }}
                />
                <VacancyVideoModal
                    vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                    visible={visible}
                    src={videoVacancyUrl}
                    onClose={() => {
                        setVisible(false);
                        videoVacancyCloseButtonClick(analyticsParams);
                    }}
                    onOpen={() => setVisible(true)}
                    onPlay={() => videoVacancyPlayButtonClick(analyticsParams)}
                    onPause={() => videoVacancyPauseButtonClick(analyticsParams)}
                    onTimeUpdate={setCurrentTime}
                    onVolumeChange={setVolume}
                    onDurationLoaded={setDuration}
                    onRespondClick={() => videoVacancyRespondButtonClick(analyticsParams)}
                />
            </Wrapper>
        </Container>
    );
};

export default memo(translation(VacancyVideo));
