import { FC, MouseEventHandler, useCallback } from 'react';
import classnames from 'classnames';

import { Link } from '@hh.ru/redux-spa-middleware';
import VSpacing from 'bloko/blocks/vSpacing';

import SendHHAnalytics from 'HH/SendHHAnalytics';
import CdnImg from 'lux/components/CdnImg';
import { useSelector } from 'lux/modules/useSelector';

import useCompanyLogo from 'lux/components/VacancyView/hooks/useCompanyLogo';
import usePreview from 'lux/components/VacancyView/hooks/usePreview';

interface VacancyCompanyLogoProps {
    constructorBrandedVacancy?: boolean;
    isRedesign?: boolean;
    noPrint?: boolean;
}

const VacancyCompanyLogo: FC<VacancyCompanyLogoProps> = ({ constructorBrandedVacancy, isRedesign, noPrint }) => {
    const companyId = useSelector((state) => state.vacancyView.company.id);
    const companyName = useSelector((state) => state.vacancyView.company.name);
    const request = useSelector((state) => state.request);
    const companyLogo = useCompanyLogo();
    const isPreview = usePreview(request.url);

    const logoOnClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        (event) => {
            if (isPreview) {
                event.preventDefault();
                return;
            }
            void SendHHAnalytics({ data: { goal: 'vacancy-company-logo' } });
        },
        [isPreview]
    );

    if (!companyLogo) {
        return null;
    }

    return (
        <>
            <div
                className={
                    isRedesign
                        ? 'vacancy-company-logo-redesigned'
                        : classnames('vacancy-company-logo', {
                              'vacancy-company-logo_vacancyconstructor': constructorBrandedVacancy,
                              'vacancy-company-logo_no-print': noPrint,
                          })
                }
            >
                <Link to={`/employer/${companyId || ''}`} onClick={logoOnClick}>
                    <CdnImg
                        loading="lazy"
                        path={companyLogo['@url']}
                        alt={companyName}
                        className={
                            isRedesign
                                ? 'vacancy-company-logo-image-redesigned'
                                : classnames('vacancy-company-logo__image', {
                                      'vacancy-company-logo__image_vacancyconstructor': constructorBrandedVacancy,
                                  })
                        }
                    />
                </Link>
            </div>
            {isRedesign && <VSpacing base={4} />}
        </>
    );
};

export default VacancyCompanyLogo;
