import { useCallback, MutableRefObject } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import Button, { ButtonAppearance, ButtonKind, ButtonScale } from 'bloko/blocks/button';
import { H2 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';
import useBreakpoint from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import NoIndex from 'lux/components/NoIndex';
import VacancyContactsDrop from 'lux/components/VacancyView/VacancyContactsDrop';
import VacancySectionWrapper from 'lux/components/VacancyView/VacancySectionWrapper';
import useContactsAnalytics from 'lux/components/VacancyView/hooks/useContactsAnalytics';
import translation from 'lux/components/translation';
import { vacancySetVisibilityContactsAction } from 'lux/models/vacancyView';
import { useSelector } from 'lux/modules/useSelector';

import checkRender from 'lux/components/VacancyView/VacancyContacts/checkRender';

const TrlKeys = {
    contacts: 'vacancy.contactInfo.opened',
    showContactsButton: 'vacancy.action.showContacts',
};

interface VacancyContactsRedesignedProps {
    vacancyBodyFooterNodeRef?: MutableRefObject<HTMLDivElement>;
    blockRef: MutableRefObject<HTMLDivElement>;
    hasVacancySectionWraper?: boolean;
}
const VacancyContactsRedesigned: TranslatedComponent<VacancyContactsRedesignedProps> = ({
    vacancyBodyFooterNodeRef,
    blockRef,
    hasVacancySectionWraper = true,
    trls,
}) => {
    const dispatch = useDispatch();
    const userType = useSelector((state) => state.userType);
    const breakpoint = useBreakpoint();
    const show = useSelector((state) => checkRender(state, breakpoint));
    const sendViewContactsAnalytics = useContactsAnalytics({ place: 'vacancy_bottom_button' });
    const {
        isVisibleContacts,
        vacancyId,
        company: { id: employerId },
    } = useSelector((state) => state.vacancyView);

    const handleOpen = useCallback(() => {
        dispatch(vacancySetVisibilityContactsAction(true));
        sendViewContactsAnalytics({ vacancyId, employerId, userType });
    }, [dispatch, employerId, sendViewContactsAnalytics, userType, vacancyId]);

    if (!show) {
        return null;
    }

    return (
        <NoIndex>
            <div
                ref={blockRef}
                className={classnames('vacancy-contacts', {
                    noprint: !isVisibleContacts,
                })}
            >
                <VacancySectionWrapper hasWrapper={hasVacancySectionWraper}>
                    <H2>{trls[TrlKeys.contacts]}</H2>
                    <VSpacing base={6} />
                    <VacancyContactsDrop
                        show={isVisibleContacts}
                        vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                        place="bottom"
                    >
                        <Button
                            scale={ButtonScale.Large}
                            appearance={ButtonAppearance.Outlined}
                            kind={ButtonKind.Primary}
                            onClick={handleOpen}
                            data-qa="show-employer-contacts show-employer-contacts_bottom-button"
                        >
                            {trls[TrlKeys.showContactsButton]}
                        </Button>
                    </VacancyContactsDrop>
                    <VSpacing base={6} />
                </VacancySectionWrapper>
            </div>
        </NoIndex>
    );
};

export default translation(VacancyContactsRedesigned);
