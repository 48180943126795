import { DefaultRootState } from 'react-redux';

import { Link as MagritteLink } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import Conversion from 'bloko/blocks/conversion';
import Gap from 'bloko/blocks/gap';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import urlParser from 'bloko/common/urlParser';

import translation from 'lux/components/translation';
import paths from 'lux/modules/routePaths';
import { useSelector } from 'lux/modules/useSelector';

const TrlKeys = {
    show: 'vacancy.view.suitable.link.show',
    resume: 'vacancy.view.suitable.link.resume',
    one: 'vacancy.view.suitable.link.suitable.one',
    some: 'vacancy.view.suitable.link.suitable.many',
    many: 'vacancy.view.suitable.link.suitable.many',
    ending: 'vacancy.view.suitable.link.ending',
};

export const checkVacancySimilarResumesRender = ({ resumeSearchResult }: DefaultRootState): boolean =>
    !!resumeSearchResult.totalResults;

const VacancySimilarResumes: TranslatedComponent = ({ trls }) => {
    const similarResumes = useSelector((state) => state.resumeSearchResult);
    const show = useSelector(checkVacancySimilarResumesRender);
    const totalResults = similarResumes.totalResults;

    if (!show || typeof totalResults !== 'number') {
        return null;
    }

    const url = urlParser(paths.resumeSearch);
    url.params = {
        vacancy_id: [similarResumes.searchCriteria?.vacancy_id], // eslint-disable-line camelcase
        order_by: ['relevance'], // eslint-disable-line camelcase
        from: ['vacancy'],
    };

    return (
        <div className="noprint">
            <Gap bottom>
                <MagritteLink Element={Link} to={url.href}>
                    {trls[TrlKeys.show]} {totalResults} {trls[TrlKeys.resume]},&nbsp;
                    <Conversion
                        hasValue={false}
                        value={totalResults}
                        one={trls[TrlKeys.one]}
                        some={trls[TrlKeys.some]}
                        many={trls[TrlKeys.many]}
                    />{' '}
                    {trls[TrlKeys.ending]}
                </MagritteLink>
            </Gap>
        </div>
    );
};

export default translation(VacancySimilarResumes);
