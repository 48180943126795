import { ReactNode } from 'react';
import classnames from 'classnames';

import Column, { ColumnsRow } from 'bloko/blocks/column';
import ConversionNumber from 'bloko/blocks/conversion';
import { FormSeparator } from 'bloko/blocks/form';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

const TrlKeys = {
    title: 'vacancy.view.online.title',
    watching: {
        one: 'vacancy.view.online.one.watching',
        many: 'vacancy.view.online.many.watching',
    },
    viewer: {
        one: 'vacancy.view.online.many.viewer',
        some: 'vacancy.view.online.two.viewer',
        many: 'vacancy.view.online.many.viewer',
    },
};

interface VacancyOnlineUsersCounterProps {
    light?: boolean;
    isRedesign?: boolean;
}

const VacancyOnlineUsersCounter: TranslatedComponent<VacancyOnlineUsersCounterProps> = ({
    trls,
    light,
    isRedesign,
}) => {
    const { vacancyId, status, features } = useSelector(({ vacancyView }) => vacancyView);
    const vacancyOnlineUsersCounters = useSelector((state) => state.vacancyOnlineUsersCounters);
    if (!vacancyOnlineUsersCounters || !vacancyId) {
        return null;
    }
    const countersForVacancy = vacancyOnlineUsersCounters[vacancyId];
    const hide = !!(!countersForVacancy?.excludingCurrent || status.archived || features.includes('edit'));
    if (hide) {
        return null;
    }
    const renderWrapper = (content: ReactNode) => {
        if (isRedesign) {
            return (
                <div className="noprint">
                    {content}
                    <VSpacing base={3} />
                </div>
            );
        }
        return (
            <div className="noprint">
                <ColumnsRow>
                    <Column xs="4" s="8" m="8" l="10">
                        <VSpacing base={2} />
                        <div>{content}</div>
                        <FormSeparator />
                    </Column>
                </ColumnsRow>
            </div>
        );
    };
    return renderWrapper(
        <>
            {trls[TrlKeys.title]}
            <ConversionNumber
                value={countersForVacancy.includingCurrent}
                one={trls[TrlKeys.watching.one]}
                some={trls[TrlKeys.watching.many]}
                many={trls[TrlKeys.watching.many]}
                hasValue={false}
            />{' '}
            <span
                className={classnames('vacancy-viewers-count', {
                    'vacancy-viewers-count_light': light,
                })}
            >
                <ConversionNumber
                    value={countersForVacancy.includingCurrent}
                    one={trls[TrlKeys.viewer.one]}
                    some={trls[TrlKeys.viewer.some]}
                    many={trls[TrlKeys.viewer.many]}
                    hasValue={true}
                />
            </span>
        </>
    );
};

export default translation(VacancyOnlineUsersCounter);
