import { RefObject, FC } from 'react';
import { DefaultRootState } from 'react-redux';

import { VSpacing as MagritteVSpacing } from '@hh.ru/magritte-ui';
import Column from 'bloko/blocks/column';
import Gap from 'bloko/blocks/gap';
import VSpacing from 'bloko/blocks/vSpacing';

import RespondedSuccess from 'lux/components/Applicant/Response/RespondedSuccess';
import { TopLevelSite } from 'lux/models/topLevelSite';
import { UserType } from 'lux/models/userType';
import { useSelector } from 'lux/modules/useSelector';

export const checkVacancyApplicantRespondedInfoRender = (state: DefaultRootState): boolean => {
    if (![UserType.Applicant, UserType.NedoUser].includes(state.userType)) {
        return false;
    }

    const vacancyId = state.vacancyView?.vacancyId;
    const responseStatus = vacancyId ? state.applicantVacancyResponseStatuses?.[vacancyId] : undefined;
    const alreadyResponded = (responseStatus?.usedResumeIds?.length || 0) > 0;

    return !state.vacancyView.showResumeForm && alreadyResponded;
};

interface VacancyApplicantRespondedInfoProps {
    vacancyBodyFooterNodeRef: RefObject<HTMLDivElement>;
    isRedesign: boolean;
    withWrapper?: boolean;
    isMagritteExp?: boolean;
}

const VacancyApplicantRespondedInfo: FC<VacancyApplicantRespondedInfoProps> = ({
    vacancyBodyFooterNodeRef,
    isRedesign,
    withWrapper,
    isMagritteExp,
}) => {
    const showRespondedInfo = useSelector(checkVacancyApplicantRespondedInfoRender);
    const vacancyId = useSelector(({ vacancyView }) => vacancyView.vacancyId);
    const topLevelSite = useSelector((state) => state.topLevelSite);
    const showNewFooter =
        topLevelSite === TopLevelSite.RU || topLevelSite === TopLevelSite.BY || topLevelSite === TopLevelSite.ZP;

    if (!showRespondedInfo) {
        return null;
    }

    const content = vacancyId && (
        <div className="vacancy-actions_responded">
            <RespondedSuccess
                vacancyId={vacancyId}
                isRedesign={isRedesign}
                {...(!showNewFooter && { defaultView: 'vacancybody' })}
            />
        </div>
    );

    const renderContentWithWrapper = () => {
        if (isMagritteExp) {
            return (
                <Column xs="4" s="8" m="12" l="10">
                    <MagritteVSpacing default={36} gteM={48} />
                    {content}
                </Column>
            );
        }

        return (
            <Column xs="4" s={showNewFooter ? '7' : '8'} m={showNewFooter ? '7' : '8'} l={showNewFooter ? '7' : '10'}>
                <Gap top>{content}</Gap>
                <VSpacing base={4} />
            </Column>
        );
    };

    return (
        <>
            <div ref={vacancyBodyFooterNodeRef} className="vacancy-actions-responded-scroll-target" />
            <div className="noprint">{withWrapper ? renderContentWithWrapper() : <>{content}</>}</div>
        </>
    );
};

export default VacancyApplicantRespondedInfo;
