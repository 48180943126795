import { MouseEvent, MutableRefObject } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import askVacancyQuestionButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/ask_vacancy_question_button_click';
import { LinkChip } from 'bloko/blocks/chips';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import VacancyResponseLink from 'lux/components/VacancyResponseLink';
import { saveVacancyResponseQuestion } from 'lux/components/VacancyView/hooks/useResponseQuestion';
import translation from 'lux/components/translation';
import { ResponseQuestion } from 'lux/models/vacancyResponseQuestions';
import { useSelector } from 'lux/modules/useSelector';

import styles from './VacancyResponseQuestions.less';

const TrlKeys = {
    questions: {
        [ResponseQuestion.WorkPlaceLocation]: 'vacancy.view.responseQuestions.item.work_place_location',
        [ResponseQuestion.EmploymentAndWorkMode]: 'vacancy.view.responseQuestions.item.employment_and_work_mode',
        [ResponseQuestion.IsVacancyOpen]: 'vacancy.view.responseQuestions.item.is_vacancy_open',
        [ResponseQuestion.SalaryOptions]: 'vacancy.view.responseQuestions.item.salary_options',
        [ResponseQuestion.HowToContact]: 'vacancy.view.responseQuestions.item.how_to_contact',
        [ResponseQuestion.Other]: 'vacancy.view.responseQuestions.item.other',
    },
    questionsFull: {
        [ResponseQuestion.WorkPlaceLocation]: 'vacancy.view.responseQuestions.item.full.work_place_location',
        [ResponseQuestion.EmploymentAndWorkMode]: 'vacancy.view.responseQuestions.item.full.employment_and_work_mode',
        [ResponseQuestion.IsVacancyOpen]: 'vacancy.view.responseQuestions.item.full.is_vacancy_open',
        [ResponseQuestion.SalaryOptions]: 'vacancy.view.responseQuestions.item.full.salary_options',
        [ResponseQuestion.HowToContact]: 'vacancy.view.responseQuestions.item.full.how_to_contact',
    },
    questionGreeting: 'vacancy.view.responseQuestions.greeting',
};

interface SuggestProps {
    vacancyId: number;
    vacancyBodyFooterNodeRef: MutableRefObject<HTMLDivElement>;
    type: 'question' | 'response';
    onClick: (text: string) => void;
}

const Suggest: TranslatedComponent<SuggestProps> = ({ trls, vacancyId, vacancyBodyFooterNodeRef, type, onClick }) => {
    const dispatch = useDispatch();
    const employerId = useSelector(({ vacancyView }) => vacancyView.company.id);
    const showSignupForm = useSelector(({ vacancyView }) => vacancyView.showSignupForm);

    const sendClickAnalytics = ({ value, vacancyId }: { value: string; vacancyId: number }) => {
        askVacancyQuestionButtonClick({ value, vacancyId });
    };

    return (
        <div className={styles.vacancyResponseSuggest}>
            {Object.values(ResponseQuestion).map((question) => {
                if (type === 'question' && question === ResponseQuestion.Other) {
                    return null;
                }

                return type === 'response' ? (
                    <VacancyResponseLink
                        key={question}
                        render={(onResponseClick) => (
                            <LinkChip
                                href="#"
                                data-qa={classnames(
                                    'vacancy-response-question',
                                    `vacancy-response-question_${question}`
                                )}
                                onClick={(event: MouseEvent) => {
                                    event.preventDefault();
                                    const questionText =
                                        question === ResponseQuestion.Other
                                            ? ''
                                            : trls[TrlKeys.questionsFull[question]];
                                    sendClickAnalytics({
                                        value: questionText,
                                        vacancyId,
                                    });
                                    saveVacancyResponseQuestion({
                                        vacancyId,
                                        question: questionText,
                                        questionId: question,
                                        dispatch,
                                    });
                                    onResponseClick(event);
                                }}
                            >
                                {trls[TrlKeys.questions[question]]}
                            </LinkChip>
                        )}
                        vacancyId={vacancyId}
                        employerId={employerId}
                        scrollToSignupForm={showSignupForm}
                        vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                        enableRelocationWarning
                        place="vacancy_response_questions"
                    />
                ) : (
                    <LinkChip
                        key={question}
                        href="#"
                        data-qa={classnames('vacancy-response-question', `vacancy-response-question_${question}`)}
                        onClick={(event: MouseEvent) => {
                            event.preventDefault();
                            const questionText =
                                question === ResponseQuestion.Other
                                    ? ''
                                    : `${trls[TrlKeys.questionGreeting]} ${trls[TrlKeys.questions[question]]}`;
                            sendClickAnalytics({ value: questionText, vacancyId });
                            onClick(questionText);
                        }}
                    >
                        {trls[TrlKeys.questions[question]]}
                    </LinkChip>
                );
            })}
        </div>
    );
};

export default translation(Suggest);
