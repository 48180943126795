import { FC, RefObject } from 'react';

import Column, { ColumnsRow } from 'bloko/blocks/column';
import VSpacing from 'bloko/blocks/vSpacing';

import VacancyCompanyRedesigned from 'lux/components/VacancyView/VacancyCompanyRedesigned';
import VacancyVideo from 'lux/components/VacancyView/VacancyVideo';

import styles from './vacancy-block.less';

const VacancyBlockEmployer: FC<{ vacancyBodyFooterNodeRef?: RefObject<HTMLDivElement> }> = ({
    vacancyBodyFooterNodeRef,
}) => {
    return (
        <ColumnsRow>
            <Column xs="4" s="8" m="12" l="6">
                <div className={styles.blockEmployer}>
                    <div className={styles.wrapper}>
                        <VacancyCompanyRedesigned />
                    </div>
                </div>
                <VacancyVideo vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef} />
            </Column>
            <VSpacing base={14} xs={9} />
        </ColumnsRow>
    );
};

export default VacancyBlockEmployer;
