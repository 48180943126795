import { H2 } from 'bloko/blocks/header';
import Text, { TextImportance } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import VerificationForm, { VerificationFormBaseProps } from 'lux/components/Verification/VerificationForm';
import translation from 'lux/components/translation';

import styles from './verification-step.less';

const TrlKeys = {
    header: 'signUpForm.verification.header',
    phone: {
        sent: 'signUpForm.verification.codeSent.phone',
        description1: 'signUpForm.verification.codeSentDescription.1.phone',
        description2: 'signUpForm.verification.codeSentDescription.2.phone',
    },
    email: {
        sent: 'signUpForm.verification.codeSent.email',
        description: 'otp.confirm.sub.header',
    },
};

export interface VerificationStepProps extends VerificationFormBaseProps {
    onCancel?: () => void;
    cancelText?: string;
    codePlaceholder?: string;
    errorPageLogin?: boolean;
}

const VerificationStep: TranslatedComponent<VerificationStepProps> = ({
    trls,
    errorPageLogin,
    codePlaceholder,
    onCancel,
    cancelText,
    ...formProps
}) => {
    const { authType, login } = formProps;

    return (
        <VerificationForm
            render={({ renderInput, renderCodeError, renderCodeSender, renderSubmit }) => {
                return (
                    <div className={styles.wrapper}>
                        <H2>{trls[TrlKeys.header]}</H2>
                        <VSpacing base={2} />
                        <p>
                            {formatToReactComponent(
                                trls[authType === 'EMAIL' ? TrlKeys.email.sent : TrlKeys.phone.sent],
                                {
                                    '{0}': <strong>{login}</strong>,
                                }
                            )}
                        </p>
                        <VSpacing base={2} />
                        {authType === 'EMAIL' ? (
                            <p>{trls[TrlKeys.email.description]}</p>
                        ) : (
                            <>
                                <p>{trls[TrlKeys.phone.description1]}</p>
                                <p>{trls[TrlKeys.phone.description2]}</p>
                            </>
                        )}
                        <VSpacing base={6} xs={4} />
                        <div className={styles.formContainer}>
                            <div className={styles.inputWrapper}>
                                {renderInput({
                                    placeholder: codePlaceholder,
                                    isAutofocus: true,
                                })}
                            </div>
                            <div className={styles.buttonWrapper}>{renderSubmit({ isStretched: true })}</div>
                        </div>
                        <div>
                            {renderCodeError()}
                            <VSpacing base={6} xs={4} />
                            <Text importance={TextImportance.Tertiary}>{renderCodeSender()}</Text>
                        </div>
                    </div>
                );
            }}
            {...formProps}
        />
    );
};

export default translation(VerificationStep);
