import { RefObject } from 'react';

import Button, { ButtonType, ButtonKind } from 'bloko/blocks/button';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

import VacancyActionComplain from 'lux/components/VacancyView/VacancyActionComplain';
import VacancyActionResponse, { VacancyActionResponseKind } from 'lux/components/VacancyView/VacancyActionResponse';

const TrlKeys = {
    response: 'button.placeResponse',
};

interface VacancyResponseProps {
    hideResponded?: boolean;
    isRedesign?: boolean;
    vacancyBodyFooterNodeRef?: RefObject<HTMLDivElement>;
}

const VacancyResponse: TranslatedComponent<VacancyResponseProps> = ({
    hideResponded = false,
    trls,
    isRedesign,
    vacancyBodyFooterNodeRef,
}) => {
    const showSignupForm = useSelector((state) => state.vacancyView.showSignupForm);
    const status = useSelector((state) => state.vacancyView.status);
    const isBrandingPreview = useSelector((state) => state.vacancyView.isBrandingPreview);
    const isTrusted = useSelector((state) => state.vacancyView.company['@trusted']);

    if (showSignupForm || status.archived || status.disabled) {
        return null;
    }

    return isBrandingPreview && !isRedesign ? (
        <Button type={ButtonType.Button} kind={ButtonKind.Success} stretched>
            {trls[TrlKeys.response]}
        </Button>
    ) : (
        <>
            <VacancyActionResponse
                isSecondary
                hideResponded={hideResponded}
                isRedesign={isRedesign}
                kind={VacancyActionResponseKind.AfterBody}
                vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
            />
            {!isTrusted && <VacancyActionComplain />}
        </>
    );
};

export default translation(VacancyResponse);
