import { FC, RefObject, MutableRefObject, PropsWithChildren } from 'react';
import classnames from 'classnames';

import Column, { ColumnsRow } from 'bloko/blocks/column';
import VSpacing from 'bloko/blocks/vSpacing';

import VacancyAcceptTemporary from 'lux/components/VacancyView/VacancyAcceptTemporary';
import VacancyActions from 'lux/components/VacancyView/VacancyActions';
import VacancyEmployment from 'lux/components/VacancyView/VacancyEmployment';
import VacancyOnlineUsersCounter from 'lux/components/VacancyView/VacancyOnlineUsersCounter';
import VacancyParttimeOptions from 'lux/components/VacancyView/VacancyParttimeOptions';
import VacancyResponseQuestions from 'lux/components/VacancyView/VacancyResponseQuestions';
import VacancyTitleRedesigned from 'lux/components/VacancyView/VacancyTitleRedesigned';
import VacancyWorkExperience from 'lux/components/VacancyView/VacancyWorkExperience';

import styles from './vacancy-block.less';

const VacancyBlockTopWrapper: FC<PropsWithChildren & { hasWrapper: boolean }> = ({ children, hasWrapper }) => {
    return hasWrapper ? (
        <ColumnsRow>
            <Column xs="4" s="8" m="12" l="10">
                <div className={styles.wrapperFlat}>{children}</div>
            </Column>
            <VSpacing base={9} xs={6} />
        </ColumnsRow>
    ) : (
        <>{children}</>
    );
};

interface VacancyBlockTopProps {
    resumeFormNodeRef: RefObject<HTMLDivElement>;
    contactsNodeRef: RefObject<HTMLDivElement>;
    vacancyBodyFooterNodeRef: MutableRefObject<HTMLDivElement>;
    hasWrapper?: boolean;
    isChatQuestionExp?: boolean;
    hasQuestion?: boolean;
}

const VacancyBlockTop: FC<VacancyBlockTopProps> = ({
    resumeFormNodeRef,
    contactsNodeRef,
    vacancyBodyFooterNodeRef,
    hasWrapper = true,
    isChatQuestionExp,
    hasQuestion,
}) => {
    return (
        <VacancyBlockTopWrapper hasWrapper={hasWrapper}>
            <VacancyTitleRedesigned />
            <VSpacing base={5} />
            <VacancyWorkExperience isRedesign />
            <VacancyEmployment isRedesign />
            <VacancyAcceptTemporary isRedesign />
            <VacancyParttimeOptions isRedesign />
            <div className="noprint">
                <VSpacing base={7} />
                <VacancyOnlineUsersCounter isRedesign />
                <VacancyActions
                    contactsNodeRef={contactsNodeRef}
                    vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                    resumeFormNodeRef={resumeFormNodeRef}
                    isRedesign
                    hasQuestion={hasQuestion}
                />
            </div>
            {isChatQuestionExp && !hasQuestion && (
                <div className={classnames(styles.question, 'noprint')}>
                    <VacancyResponseQuestions
                        type="question"
                        vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                        hasBottomGap={false}
                        hasSuggest={false}
                    />
                </div>
            )}
        </VacancyBlockTopWrapper>
    );
};

export default VacancyBlockTop;
