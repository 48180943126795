import { FC } from 'react';
import { DefaultRootState } from 'react-redux';

import Column from 'bloko/blocks/column';

import { UserType } from 'lux/models/userType';
import { useSelector } from 'lux/modules/useSelector';

import VacancyResponse from 'lux/components/VacancyView/VacancyResponse';

export const checkVacancyAnonymousResponseRender = (state: DefaultRootState): boolean => {
    return [UserType.Anonymous, UserType.NedoUser].includes(state.userType);
};

export interface VacancyAnonymousResponseProps {
    isRedesign: boolean;
}

const VacancyAnonymousResponse: FC<VacancyAnonymousResponseProps> = ({ isRedesign }) => {
    const showResponse = useSelector(checkVacancyAnonymousResponseRender);

    if (!showResponse) {
        return null;
    }

    return (
        <div className="noprint">
            <Column xs="4" s="8" m={isRedesign ? '12' : '8'} l={isRedesign ? '10' : '8'}>
                <div className="vacancy-actions vacancy-actions_applicant">
                    <div className="vacancy-action vacancy-action_after-body">
                        <VacancyResponse isRedesign={isRedesign} />
                    </div>
                </div>
            </Column>
        </div>
    );
};

export default VacancyAnonymousResponse;
