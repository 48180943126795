import classnames from 'classnames';

import { H1Section } from 'bloko/blocks/header';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import HumanDate, { ShowYear } from 'lux/components/HumanDate';
import AsyncHighlighter from 'lux/components/Markup/AsyncHighlighter';
import translation from 'lux/components/translation';
import { NON_BREAKING_SPACE } from 'lux/modules/symbols';
import { useSelector } from 'lux/modules/useSelector';

import VacancyCompensation from 'lux/components/VacancyView/VacancyCompensation';

const TrlKeys = {
    archivedFrom: 'vacancy.view.archived.from',
};
const VacancyTitleRedesigned: TranslatedComponent = ({ trls }) => {
    const { status, name, validThroughTime } = useSelector((state) => state.vacancyView);
    return (
        <div
            className={classnames('vacancy-title', {
                'vacancy-title_archive': status.archived || status.disabled,
            })}
        >
            <H1Section data-qa="vacancy-title">
                <AsyncHighlighter>{name}</AsyncHighlighter>
            </H1Section>
            {status.archived && validThroughTime && (
                <Text strong size={TextSize.Small}>
                    {trls[TrlKeys.archivedFrom]}
                    {NON_BREAKING_SPACE}
                    <HumanDate date={validThroughTime} showYear={ShowYear.Always} />
                </Text>
            )}
            <VSpacing base={4} />
            <VacancyCompensation isRedesign />
        </div>
    );
};

export default translation(VacancyTitleRedesigned);
