import { useRef, useEffect } from 'react';
import { DefaultRootState, useDispatch } from 'react-redux';
import classnames from 'classnames';

import addressMapButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/address_map_button_click';
import { Title, VSpacing, Link as MagritteLink, Text } from '@hh.ru/magritte-ui';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { Link } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

import VacancyCompanyAddress from 'lux/components/VacancyView/VacancyCompanyAddress';
import VacancySectionWrapper from 'lux/components/VacancyView/VacancySectionWrapper';

const TrlKeys = {
    title: 'vacancy.address',
    showOnMap: 'vacancy.address.showOnBigMap.action',
};

export const checkVacancyMapRender = (state: DefaultRootState): boolean => !!state.vacancyView.address?.mapData?.points;

const setIsVacancyMapLoadingCompletedAction = makeSetStoreField('isVacancyMapLoadingCompleted');

interface VacancyMapProps {
    isRedesign?: boolean;
    hasVacancySectionWrapper?: boolean;
    newEmployerVacancyMap?: boolean;
}

const VacancyMap: TranslatedComponent<VacancyMapProps> = ({
    trls,
    isRedesign,
    hasVacancySectionWrapper = true,
    newEmployerVacancyMap = false,
}) => {
    const dispatch = useDispatch();

    const mapContainer = useRef<HTMLDivElement>(null);
    const staticHost = useSelector(({ config }) => config.staticHost);
    const vacancyId = useSelector(({ vacancyView }) => vacancyView.vacancyId);
    const address = useSelector((state) => state.vacancyView.address);
    const compensation = useSelector((state) => state.vacancyView.compensation);
    const mapDisabled = useSelector((state) => state.vacancyView.mapDisabled);
    const vacancyLink = useSelector((state) => state.vacancyView.vacancyOnMapLink);
    const archived = useSelector((state) => state.vacancyView?.status?.archived);
    const mapDataAvailable = useSelector(checkVacancyMapRender);

    useEffect(() => {
        setIsVacancyMapLoadingCompletedAction(false);
        if (!mapDisabled && mapDataAvailable) {
            // Отдельный IF, чтобы карты не затягивались в lux-server
            if (!process.env.LUX_SERVER) {
                import(/* webpackMode: "eager" */ 'Modules/Maps/showMap').then(({ default: showMap, defaults }) => {
                    void (
                        mapContainer.current &&
                        showMap(mapContainer.current, {
                            address,
                            disableScrollZoom: true,
                            onPlacemarkClick: () => {
                                window.open(`${vacancyLink}${vacancyId || ''}`, '_blank');
                            },
                            placemarkZIndex: defaults.placemarkZIndex + 1,
                            zoomControlPosition: {
                                left: 10,
                                top: 30,
                            },
                            newEmployerVacancyMap,
                        }).finally(() => {
                            dispatch(setIsVacancyMapLoadingCompletedAction(true));
                        })
                    );
                }, console.error);
            }
        }
    }, [
        address,
        compensation,
        mapContainer,
        mapDisabled,
        mapDataAvailable,
        trls,
        vacancyLink,
        staticHost,
        vacancyId,
        dispatch,
        newEmployerVacancyMap,
    ]);
    if (!mapDataAvailable) {
        return null;
    }
    const onLinkClick = () => {
        if (!vacancyId) {
            return;
        }
        addressMapButtonClick({ hhtmSourceLabel: 'vacancy_bottom_button', vacancyId });
    };
    return (
        <VacancySectionWrapper hasWrapper={hasVacancySectionWrapper}>
            <Title Element="h2" size="extra-small">
                {trls[TrlKeys.title]}
            </Title>
            <VSpacing default={16} />
            <VacancyCompanyAddress
                isRedesign={isRedesign}
                WrapperComponent={({ children }) => (
                    <Text
                        data-qa="vacancy-address-with-map"
                        typography={isRedesign ? 'label-1-regular' : 'paragraph-2-regular'}
                        style="secondary"
                    >
                        {children}
                        {isRedesign && <VSpacing default={8} />}
                    </Text>
                )}
            />
            {!mapDisabled && (
                <>
                    <div className="vacancy-address-map-wrapper">
                        <div
                            className={classnames('vacancy-address-map', {
                                'vacancy-address-map_redesign': newEmployerVacancyMap,
                            })}
                            ref={mapContainer}
                        />
                    </div>
                    {!archived && (
                        <div className="noprint">
                            <MagritteLink
                                Element={Link}
                                to={`${vacancyLink}${vacancyId || ''}`}
                                target="_blank"
                                data-qa="vacancy-address-big-map-link"
                                onClick={onLinkClick}
                            >
                                {trls[TrlKeys.showOnMap]}
                            </MagritteLink>
                        </div>
                    )}
                </>
            )}
        </VacancySectionWrapper>
    );
};

export default translation(VacancyMap);
