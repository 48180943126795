import classnames from 'classnames';

import Button, { ButtonKind, ButtonAppearance } from 'bloko/blocks/button';
import { HeartScaleSmallAppearanceFilled, HeartScaleSmallAppearanceOutlined, IconColor } from 'bloko/blocks/icon';
import IconDynamic from 'bloko/blocks/icon/IconDynamic';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { getDataQa } from 'Modules/VacancyToFavorite';
import translation from 'lux/components/translation';
import useFavorite from 'lux/hooks/useFavorite';
import { useSelector } from 'lux/modules/useSelector';

const TrlKeys = {
    add: 'hint.star',
    remove: 'hint.starMirror',
};

interface VacancyActionFavoriteProps {
    constructorBrandedVacancy?: boolean;
    isRedesign?: boolean;
}

const VacancyActionFavorite: TranslatedComponent<VacancyActionFavoriteProps> = ({
    constructorBrandedVacancy,
    isRedesign,
    trls,
}) => {
    const vacancyId = useSelector(({ vacancyView }) => vacancyView.vacancyId);
    const employerId = useSelector(({ vacancyView }) => vacancyView.company.id);
    const isClosedVacancy = useSelector(({ vacancyView }) => vacancyView.closedForApplicants);
    const isBrandingPreview = useSelector(({ vacancyView }) => vacancyView.isBrandingPreview);
    const { isFavoriteVacancy, toggleIsFavoriteVacancy, isAvailableChangeFavoriteState } = useFavorite({
        vacancyId,
        employerId,
        isClosedVacancy,
        isSendUxFeedback: true,
    });
    if (!isAvailableChangeFavoriteState && !isBrandingPreview) {
        return null;
    }
    const renderIcon = () => {
        let iconColor = constructorBrandedVacancy ? IconColor.White : IconColor.Gray50;
        if (isFavoriteVacancy) {
            iconColor = IconColor.Blue60;
        }
        return isFavoriteVacancy ? (
            <HeartScaleSmallAppearanceFilled initial={iconColor} data-qa="applicant-vacancy__favorite-add" />
        ) : (
            <HeartScaleSmallAppearanceOutlined initial={iconColor} data-qa="applicant-vacancy__favorite-add" />
        );
    };
    const getKind = () => {
        if (constructorBrandedVacancy) {
            return ButtonKind.Inversed;
        }
        if (isRedesign) {
            return ButtonKind.Success;
        }
        return undefined;
    };
    const commonProps = {
        'data-qa': getDataQa(isFavoriteVacancy),
        onClick: isBrandingPreview ? undefined : toggleIsFavoriteVacancy,
        title: trls[isFavoriteVacancy ? TrlKeys.remove : TrlKeys.add],
        disabled: isBrandingPreview,
    };
    return (
        <span className="vacancy-action">
            <IconDynamic>
                {isRedesign ? (
                    <button
                        className="vacancy-action-favorite-redesign vacancy-action-favorite-redesign_appearance-outlined"
                        {...commonProps}
                    >
                        <span
                            className={classnames('vacancy-action-favorite-icon-redesign', {
                                'vacancy-action-favorite-icon-redesign_checked': isFavoriteVacancy,
                                'vacancy-action-favorite-icon-redesign_disabled': isBrandingPreview,
                            })}
                        />
                    </button>
                ) : (
                    <Button
                        appearance={ButtonAppearance.Outlined}
                        kind={getKind()}
                        icon={renderIcon()}
                        {...commonProps}
                    />
                )}
            </IconDynamic>
        </span>
    );
};

export default translation(VacancyActionFavorite);
