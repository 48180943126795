import { PropsWithChildren, FC } from 'react';

interface VacancySectionWrapperProps {
    hasWrapper?: boolean;
}

const VacancySectionWrapper: FC<VacancySectionWrapperProps & PropsWithChildren> = ({ hasWrapper, children }) => {
    if (hasWrapper) {
        return <div className="vacancy-section">{children}</div>;
    }
    return <>{children}</>;
};

export default VacancySectionWrapper;
