import { useMemo, useCallback, FC, MouseEventHandler } from 'react';

import employerButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/employer_button_click';
import { Link } from '@hh.ru/redux-spa-middleware';
import { H2Section } from 'bloko/blocks/header';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';

import ItAccreditation from 'lux/components/Employer/ItAccreditation';
import OrganizationForm from 'lux/components/Employer/OrganizationForm';
import TrustedEmployerCheck, { TrustedEmployerCheckSize } from 'lux/components/Employer/TrustedEmployerCheck';
import EmployerBadgesIconWrapper from 'lux/components/EmployerBadges/EmployerBadgesIconWrapper';
import AsyncHighlighter from 'lux/components/Markup/AsyncHighlighter';
import { Platform } from 'lux/models/locale.types';
import { BillingPlatform } from 'lux/models/price/product.types';
import { useSelector } from 'lux/modules/useSelector';

import usePreview from 'lux/components/VacancyView/hooks/usePreview';

interface VacancyCompanyNameProps {
    constructorBrandedVacancy?: boolean;
    isRedesign?: boolean;
}

const VacancyCompanyName: FC<VacancyCompanyNameProps> = ({ constructorBrandedVacancy, isRedesign }) => {
    const company = useSelector((state) => state.vacancyView.company);
    const request = useSelector((state) => state.request);
    const isCrossposted = useSelector(
        (state) =>
            state.locale.platform === Platform.Zarplata &&
            state.vacancyView.platforms.includes(BillingPlatform.HH) &&
            state.vacancyView.platforms.includes(BillingPlatform.ZP)
    );
    const isPreview = usePreview(request.url);
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const companyNameOnClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        (event) => {
            if (isPreview) {
                event.preventDefault();
                return;
            }
            if (vacancyId) {
                employerButtonClick({ vacancyId });
            }
        },
        [isPreview, vacancyId]
    );

    const { id, visibleName, employerOrganizationFormId, department } = company;
    const renderCompanyName = useMemo(() => {
        if (id) {
            if (isCrossposted) {
                return (
                    <H2Section Element="span" lite>
                        <OrganizationForm organizationFormId={employerOrganizationFormId} />
                        <AsyncHighlighter>{visibleName}</AsyncHighlighter>
                    </H2Section>
                );
            }

            let companyHref = `/employer/${id}`;
            if (department) {
                companyHref += `?dpt=${department?.['@code']}`;
            }

            return (
                <span className="vacancy-company-name">
                    <BlokoLink
                        Element={Link}
                        data-qa="vacancy-company-name"
                        to={companyHref}
                        onClick={companyNameOnClick}
                        kind={isRedesign ? LinkKind.Tertiary : undefined}
                    >
                        <H2Section Element="span" lite>
                            <OrganizationForm organizationFormId={employerOrganizationFormId} />
                            <AsyncHighlighter>{visibleName}</AsyncHighlighter>
                        </H2Section>
                    </BlokoLink>
                </span>
            );
        }

        return (
            <AsyncHighlighter>
                <span className="vacancy-company-name">
                    {isRedesign ? (
                        <H2Section Element="span" lite>
                            {visibleName}
                        </H2Section>
                    ) : (
                        visibleName
                    )}
                </span>
            </AsyncHighlighter>
        );
    }, [id, visibleName, isCrossposted, department, companyNameOnClick, isRedesign, employerOrganizationFormId]);

    return (
        <div data-qa="vacancy-company__details" className="vacancy-company-details">
            {renderCompanyName}
            <EmployerBadgesIconWrapper>
                {company['@trusted'] && <TrustedEmployerCheck size={TrustedEmployerCheckSize.Small} />}
                {company.accreditedITEmployer && (
                    <ItAccreditation isInverted={!isRedesign && constructorBrandedVacancy} />
                )}
            </EmployerBadgesIconWrapper>
        </div>
    );
};

export default VacancyCompanyName;
